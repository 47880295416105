import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImangeAction,
  getWeeklyPhotos,
  saveWeeklyPhoto,
} from "../../store/actions/dashboardAction";
import ImagePreview from "../../modals/img-preview";
import DeleteModal from "../../modals/delete-modal";

const WeeklyPhotos = ({ loginUser }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [deleteImgShow, setDeleteImgShow] = useState(false);
  const [imgId, setImgId] = useState(null);

  const { weeklyPhotos } = useSelector((state) => state.dashboard);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getWeeklyPhotos());
  }, []);

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true);
      var data = new FormData();
      data.append("weeklyPhoto", e.target.files[0]);

      await dispatch(saveWeeklyPhoto(data));
      await dispatch(getWeeklyPhotos());

      setLoading(false);
    }
  };

  const deleteImg = async () => {
    setLoading(true);
    await dispatch(deleteImangeAction({ id: imgId }));
    await dispatch(getWeeklyPhotos());

    setDeleteImgShow(false);
    setLoading(false);
    setImgId(null);
  };

  return (
    <div class="container-xxl flex-grow-1 container-p-b">
      {loginUser?.isClient ? (
        <div class="card shadow-none main-card">
          <div class="card-body">
            <div class="row g-3">
              <div class="col-md-6">
                <div class="card">
                  <h5 class="card-header pb-3 fw-semibold">
                    Ready to take your photo?
                  </h5>
                  <div class="card-body text-body">
                    <p>
                      We want to see less of you! Taking a weekly photo gives
                      you an album to look back on and visually see your
                      progress.
                    </p>

                    <p>
                      These photos are just for you and your coach to track your
                      progress. This is a judgement free zone.
                    </p>

                    <p>So grab your camera and pat yourself on the back.</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card h-100">
                  <div class="card-body text-body">
                    <label
                      for="upload"
                      class="file-upload mb-0"
                      tabindex="0">
                      {!loading && (
                        <>
                          <i class="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                          <input
                            type="file"
                            id="upload"
                            class="account-file-input"
                            accept="image/png, image/jpeg, image/jpg"
                            hidden="true"
                            onChange={uploadImage}
                          />

                          <p class="text-muted h5 semi mb-0">
                            Upload Weekly Photo
                          </p>
                        </>
                      )}

                      {loading && (
                        <p class="text-muted h5 semi mb-0">
                          Uploading Image ...
                        </p>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="card mb-3">
                  <h5 class="card-header pb-3 fw-semibold">
                    My Journey So Far
                  </h5>
                  <div class="card-body text-body">
                    <div class="swiper-container photo-slider pb-5">
                      <div class="swiper-wrapper">
                        {weeklyPhotos?.map((data) => (
                          <div
                            class="swiper-slide"
                            key={data?.id}>
                            <div class="photo-block text-center">
                              <div
                                class="ph-img"
                                onClick={() => {
                                  setImgSrc(data?.photo);
                                  setIsOpen(true);
                                }}
                                style={{ cursor: "pointer" }}>
                                <img
                                  src={data?.photo}
                                  class="img-fluid br-15"
                                />
                              </div>
                              <div class="ph-ttl d-flex justify-content-between align-items-end">
                                Date Taken:{" "}
                                {new Date(data?.date).toLocaleDateString(
                                  "en-US",
                                )}
                                <a
                                  role="button"
                                  style={{
                                    color: "red",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteImgShow(true);
                                    setImgId(data?.id);
                                  }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24">
                                    <path
                                      fill="currentColor"
                                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                                    />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}

                        {weeklyPhotos?.length === 0 && (
                          <p
                            class="text-muted h5 semi mb-0"
                            style={{
                              color: "#000",
                              fontSize: 16,
                              justifyContent: "center",
                              display: "flex",
                              width: "100%",
                              marginTop: 40,
                            }}>
                            No photos added yet
                          </p>
                        )}
                      </div>

                      <div class="swiper-scrollbar mt-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <p
            style={{
              fontSize: 18,
              paddingTop: 45,
              height: 120,
              textAlign: "center",
            }}
            className="card-header pb-3 fw-semibold">
            Content will be displayed here once your program begins.
          </p>
        </div>
      )}
      <ImagePreview
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        imgSrc={imgSrc}
        closeModal={() => setIsOpen(false)}
      />
      <DeleteModal
        modalIsOpen={deleteImgShow}
        setIsOpen={setDeleteImgShow}
        deleteHandler={deleteImg}
        isLoading={loading}
        closeModal={() => setDeleteImgShow(false)}
      />
    </div>
  );
};

export default WeeklyPhotos;
