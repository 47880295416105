import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getHabits,
  saveHabitsInfo,
  updateHabitsInfo,
} from "../store/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";

const weekdays = ["M", "T", "W", "TH", "F", "SAT", "SUN"];
const habitTypes = [
  "Weighed myself",
  "Took Drops 3x",
  "Ate my 2-2-2-2",
  "Finished Water",
];

const HabitTracker = () => {
  const [weekDays, setWeekDays] = useState([]);
  const [trackerData, setTrackerData] = useState([]);
  const [week, setWeek] = useState(0);
  const [disablePrev, setDisablePrev] = useState(false);

  const { loginUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const currentWeekDays = () => {
    var startOfWeek = moment().subtract(week, "weeks").startOf("isoWeek");
    var endOfWeek = moment().subtract(week, "weeks").endOf("isoWeek");

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, "d");
    }

    if (loginUser?.planStartedOn <= new Date(days[0]).getTime()) {
      setDisablePrev(false);
    } else {
      setDisablePrev(true);
    }

    setWeekDays(days);
  };

  useEffect(() => {
    getHabitsData();
  }, []);

  useEffect(() => {
    if (loginUser?.planStartedOn) currentWeekDays();
  }, [week, loginUser?.planStartedOn]);

  const getHabitsData = async () => {
    const res = await dispatch(getHabits());

    if (res?.tracking_data) {
      setTrackerData(
        res?.tracking_data?.map((val) => ({
          tracking_data: JSON.parse(val?.tracking_data),
          date: moment(new Date(val?.date?.replace(/-/g, "/"))).format(
            "YYYY-MM-DD",
          ),
        })),
      );
    }
  };

  const handleChange = (e, date, type) => {
    let currentVal = [];

    const checkForVal = trackerData?.find(
      (val) =>
        val?.date ===
        moment(new Date(date?.replace(/-/g, "/"))).format("YYYY-MM-DD"),
    );

    if (checkForVal) {
      currentVal.push(...checkForVal.tracking_data);
    }

    if (e.target.checked) {
      currentVal.push(type);
    } else {
      currentVal = currentVal?.filter((val) => val !== type);
    }

    const payload = {
      date: moment(new Date(date?.replace(/-/g, "/"))).format("YYYY-MM-DD"),
      tracking_data: currentVal,
    };

    setTrackerData((prev) => [
      ...prev?.filter((data) => data?.date !== payload?.date),
      payload,
    ]);

    if (checkForVal) {
      dispatch(updateHabitsInfo(payload));
    } else {
      dispatch(saveHabitsInfo(payload));
    }
  };

  const checkBoxChecked = (date, val) => {
    return trackerData
      ?.find(
        (val) =>
          val?.date ===
          moment(new Date(date?.replace(/-/g, "/"))).format("YYYY-MM-DD"),
      )
      ?.tracking_data?.includes(val);
  };

  const findPercentage = () => {
    var startOfWeek = moment().startOf("isoWeek");

    const data = trackerData
      ?.filter(
        (val) =>
          val?.date >= moment(new Date(startOfWeek)).format("YYYY-MM-DD"),
      )
      ?.map((val) => val?.tracking_data?.length)
      ?.reduce((partialSum, a) => partialSum + a, 0);

    return data ? Math.round((data * 100) / 28) : 0;
  };

  return (
    <div className="card mb-3">
      <div className="card gradient4 text-white">
        <div
          className="card-body"
          style={{
            padding: "1rem 0rem",
            textAlign: "center",
            overflowY: "auto",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: -5,
            }}>
            <a
              style={{
                fontSize: 28,
                color: disablePrev ? "#ccc" : "#fff",
                cursor: disablePrev ? "default" : "pointer",
              }}
              role="button"
              onClick={() => setWeek((val) => (disablePrev ? val : val + 1))}>
              <i class="bx bx-chevron-left"></i>
            </a>

            <h4 className="fw-bold mb-2">Daily M2 Habit Tracker</h4>

            <a
              style={{
                fontSize: 28,
                color: week === 0 ? "#ccc" : "#fff",
                cursor: week === 0 ? "default" : "pointer",
              }}
              role="button"
              onClick={() => setWeek((val) => (val > 0 ? val - 1 : val))}>
              <i class="bx bx-chevron-right"></i>
            </a>
          </div>

          <table
            className="table table-bordered daily-habit-table"
            style={{ color: "#fff" }}>
            <thead>
              <tr>
                <th></th>
                {weekdays?.map((val, i) => (
                  <th style={{ fontSize: 12 }}>
                    {val} <br />{" "}
                    <span style={{ fontSize: 11 }}>
                      {moment(weekDays[i]).format("MM-DD")}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {habitTypes?.map((type, i) => (
                <tr>
                  <td>{type}</td>
                  {weekdays?.map((val, j) => (
                    <td>
                      <input
                        onClick={(e) =>
                          handleChange(
                            e,
                            moment(weekDays[j]).format("MM-DD-YYYY"),
                            i,
                          )
                        }
                        checked={checkBoxChecked(
                          moment(weekDays[j]).format("MM-DD-YYYY"),
                          i,
                        )}
                        style={{
                          marginRight: 5,
                          marginBottom: 10,
                          marginTop: 10,
                          cursor: "pointer",
                          borderRadius: "50%",
                          border: "1px solid #ccc",
                        }}
                        className="form-check-input form-check-input-white col-md-1 habit-checkbox"
                        type="checkbox"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p style={{ marginTop: 10, fontWeight: 700 }}>
            Habits Completed {findPercentage()}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default HabitTracker;
