import React, { useState } from "react";
import Modal from "react-modal";
import AddPostsUI from "./add-posts-ui";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "transparent",
  },
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};

const AddPosts = ({ modalIsOpen, afterOpenModal, closeModal, imgSrc }) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Post">
        <div className="modal-header">
          <button
            style={{ marginTop: 40, marginRight: 10 }}
            type="button"
            onClick={closeModal}
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i className="bx bx-plus bx-rotate-45"></i>
          </button>
        </div>
        <img
          src={imgSrc}
          style={{ minHeight: "100%", maxHeight: "90vh", width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default AddPosts;
