import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { affiliateCode, successScream } from "../store/actions/dashboardAction";
import FormTextField from "../forms/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { inputValidator } from "../utils/functions";

const schema2 = yup.object({
  user_first_name: yup.string().trim().required("Cannot be empty"),
  user_last_name: yup.string().trim().required("Cannot be empty"),
  user_email: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(inputValidator.emailRegx, "Invalid Email"),
});

const ReferFrindUI = ({ closeModal, modalType, message }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState(
    modalType === "Refer Friend" || modalType === "Redeem" ? "success" : "form",
  );

  const { loginUser } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema2),
  });

  useEffect(() => {
    if (loginUser?.name && modalType !== "Refer Friend") {
      setValue("user_first_name", loginUser?.name?.split(" ")?.[0] || "");
      setValue(
        "user_last_name",
        loginUser?.name?.split(" ")?.length
          ? loginUser?.name?.split(" ")?.[1]
          : loginUser?.name?.split(" ")?.[0] || "",
      );
      setValue("user_email", loginUser?.useremail);
    }
  }, [loginUser, modalType]);

  const saveData = async (data) => {
    setIsLoading(true);

    let res;
    if (modalType === "Get Personal Affiliate Code") {
      delete data?.user_phone;
      res = await dispatch(affiliateCode(data));
    } else {
      delete data?.user_phone;
      res = await dispatch(successScream(data));
    }

    if (res.status === "success") {
      setPageType("success");
    }
    setIsLoading(false);
  };
  return (
    <div className="modal-content modal-content-refer">
      <div className="modal-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={closeModal}
          aria-label="Close">
          <i className="bx bx-plus bx-rotate-45"></i>
        </button>
        <h5 className="mb-2 bold">{modalType}</h5>
      </div>
      <div className="modal-body">
        {pageType === "form" ? (
          <form
            className="d-flex flex-column justify-content-between h-100"
            onSubmit={handleSubmit(saveData)}>
            <div>
              <FormTextField
                divClassName="col-md-12"
                innerDivClassName="col-md-12"
                label="First Name"
                fieldName="user_first_name"
                register={register}
                errors={errors}
              />
              <FormTextField
                divClassName="col-md-12 mt-3"
                innerDivClassName="col-md-12"
                label="Last Name"
                fieldName="user_last_name"
                register={register}
                errors={errors}
              />
              <FormTextField
                divClassName="col-md-12 mt-3"
                innerDivClassName="col-md-12"
                label="Email"
                fieldName="user_email"
                register={register}
                errors={errors}
              />
            </div>

            <div className="modal-footer mt-4">
              <button
                className="btn w-100 btn-lg br-10"
                type="submit"
                disabled={isLoading}>
                {isLoading ? "Processing" : "Submit"}
              </button>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <p style={{ fontSize: 18, color: "#1e2a42", fontWeight: 600 }}>
              {message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferFrindUI;
