import React from "react";

const TextField = ({
  divClasss = "input-group input-group-merge",
  spanClass = "input-group-text",
  text = "",
  inputType = "text",
  placeholder = "",
  register,
  fieldName,
  errors,
  viewPassword,
}) => {
  return (
    <>
      <div class={divClasss}>
        <span class={spanClass}>{text}</span>
        <input
          type={inputType}
          class="form-control bg-white"
          placeholder={placeholder}
          {...register(`${fieldName}`)}
        />
        {fieldName === "password" && (
          <span class="input-group-text cursor-pointer" onClick={viewPassword}>
            <i class="bx bx-hide"></i>
          </span>
        )}
      </div>
      <p style={{ color: "red", fontSize: "12px", marginTop: 4 }}>
        {errors?.[fieldName]?.message}
      </p>
    </>
  );
};

export default TextField;
