import { SET_UI_DATA } from "../constants/uiConstants";

export const setUIKey = (key, value) => async (dispatch) => {
  dispatch({
    type: SET_UI_DATA,
    payload: {
      [key]: value,
    },
  });
};

