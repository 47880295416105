import React from "react";
import { setLocalData } from "../utils/functions";

const FormTextField = ({
  highFatClick,
  divClassName = "row mb-3 align-items-center",
  innerDivClassName = "col-8",
  labelClassName = "col-4 fb-label",
  inputClass = "form-control",
  label,
  register,
  fieldName,
  placeholder = "",
  errors,
  required = false,
  type = "text",
}) => {
  return (
    <div
      className={divClassName}
      style={{ paddingBottom: errors?.[fieldName]?.message ? 15 : 0 }}>
      <label className={labelClassName}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div className={innerDivClassName}>
        {label === "How do you feel?" ||
        label === "Preparation" ||
        label === "Ingredients" ||
        label === "Testimonial" ||
        label === "Add Notes" ||
        label === "Update Notes" ||
        fieldName === "comment" ? (
          <textarea
            style={{ border: "1px solid #088eac", height: 100 }}
            type="text"
            className="form-control"
            {...register(`${fieldName}`)}
          />
        ) : highFatClick ? (
          <input
            type={type}
            className={inputClass}
            {...register(`${fieldName}`)}
            onChange={(e) => {
              setLocalData("high_fat_date", e.target.value);
              highFatClick();
            }}
          />
        ) : (
          <input
            placeholder={placeholder}
            type={type}
            className={inputClass}
            {...register(`${fieldName}`)}
          />
        )}

        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginTop: 4,
            position: "absolute",
          }}>
          {errors?.[fieldName]?.message}
        </p>
      </div>
    </div>
  );
};

export default FormTextField;
