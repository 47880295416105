import React, { useState } from "react";
import Modal from "react-modal";
import TestimonialUI from "./testimonial-ui";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "transparent",
  },
  overlay: {
    zIndex: 100,
    // backgroundColor: "#1E2A42",
  },
};

const TestimonialModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  payload,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Post">
        <TestimonialUI closeModal={closeModal} payload={payload} />
      </Modal>
    </div>
  );
};

export default TestimonialModal;
