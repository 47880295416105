import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { getLocalData, sendEmailOptions } from "../utils/functions";
import { sendEmailTemplateAction } from "../store/actions/dashboardAction";
import { useDispatch } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: 220,
    overflow: "hidden",
    width: "380px",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
  },
  overlay: {
    zIndex: 100000,
  },
};

const SendEmailModal = ({ modalIsOpen, afterOpenModal, closeModal, email }) => {
  const [view, setView] = useState("confirm");
  const [tempId, setTempId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onClickAction = async () => {
    const payload = {
      contact_email: email,
      template_id: tempId,
    };

    setIsLoading(true);
    const res = await dispatch(sendEmailTemplateAction(payload));
    setIsLoading(false);

    if (res?.status === "success") {
      setView("success");
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        height={400}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div className={`authentication-wrapper confirmation-modal`}>
          <div className="form-wizard">
            {view === "success" ? (
              <h6
                style={{
                  marginTop: 20,
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                  lineHeight: 1.2,
                }}>
                Email sent
              </h6>
            ) : (
              <>
                <h5
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#333",
                  }}>
                  Choose an email template:
                </h5>
                <div className="form-group col-md-12">
                  <select
                    className="form-select form-control form-control-sm border bg-white"
                    onChange={(e) => setTempId(e.target.value)}
                    style={{ color: "#333" }}>
                    <option
                      selected
                      disabled>
                      Select
                    </option>
                    {sendEmailOptions?.map((val) => (
                      <option value={val?.templateID}>
                        {val?.templateName}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {view === "confirm" ? (
              <div
                className="row"
                style={{
                  width: "360px",
                  marginTop: 10,
                }}>
                <div style={{ width: "50%" }}>
                  <button
                    onClick={closeModal}
                    disabled={isLoading}
                    style={{
                      background: "#ccc",
                      color: "#333",
                    }}
                    className="btn w-100 mt-3 wizard-next-btn grey-btn">
                    Cancel
                  </button>
                </div>
                <div style={{ width: "50%" }}>
                  <button
                    onClick={onClickAction}
                    disabled={isLoading || !tempId}
                    style={{
                      background:
                        "linear-gradient(91.11deg, #d95b4c 1.62%, #ee7567 99.05%)",
                    }}
                    className="btn w-100 mt-3 wizard-next-btn">
                    {isLoading ? "Processing" : "Send"}
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <div
                  style={{
                    width: "350px",
                    marginTop: "-30px",
                  }}>
                  <button
                    onClick={closeModal}
                    disabled={isLoading}
                    className="btn w-100 mt-5 wizard-next-btn">
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SendEmailModal;
