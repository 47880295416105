import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormTextField from "../../forms/FormTextField";
import {
  deleteUserStats,
  getUserStats,
  getWeights,
  saveBasicsInfo,
  saveUserStats,
  updateUserData,
} from "../../store/actions/dashboardAction";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { capitalizeFirstLetter } from "../../utils/functions";
import { getUserProfile } from "../../store/actions/authAction";
import moment from "moment";
import UpdateStats from "../../modals/update-stats";
import ConfirmationModal from "../../modals/confirmation-modal";
import DateInput from "../../forms/DateInput";

const schema = yup.object({
  weight: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(/^[0-9]\d{0,9}(\.\d{1,3})?%?$/, "Invalid Value"),
  feeling: yup.string().trim().required("Cannot be empty"),
  chest: yup.string().trim(),
  waist: yup.string().trim(),
  thigh: yup.string().trim(),
  bicep: yup.string().trim(),
});

const updateSchema = yup.object({
  targetedWeight: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/, "Invalid Value"),
});

const updateSchemaCurrent = yup.object({
  startWeight: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/, "Invalid Value"),
});

const DailyStats = ({ loginUser }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingStart, setLoadingStart] = useState(false);
  const [showConfirm, setShowCofirm] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [openDrop, setIsOpenDrop] = useState(false);
  const [success, setSuccess] = useState("");
  const [successStart, setSuccessStart] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [selectedData, setSelectedData] = useState({});

  const { userStats } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  const {
    register,
    handleSubmit,
    reset,
    setValue: setValueForm,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    setValue,
    reset: resetUpdate,
    formState: { errors: errorsUpdate },
  } = useForm({
    resolver: yupResolver(updateSchema),
  });

  const {
    register: registerUpdateCurrent,
    handleSubmit: handleSubmitUpdateCurrent,
    setValue: setValueCurrent,
    reset: resetUpdateCurrent,
    formState: { errors: errorsUpdateCurrent },
  } = useForm({
    resolver: yupResolver(updateSchemaCurrent),
  });

  useEffect(() => {
    dispatch(getUserStats());
  }, []);

  const saveData = async (data) => {
    const payload = {
      weight: +data?.weight,
      feeling: data?.feeling,
      date:
        date === moment(new Date()).format("MM/DD/YYYY")
          ? new Date().getTime()
          : new Date(date).getTime(),
      chest: Number(data?.chest) ? Number(data?.chest) : 0,
      waist: Number(data?.waist) ? Number(data?.waist) : 0,
      bicep: Number(data?.bicep) ? Number(data?.bicep) : 0,
      thigh: Number(data?.thigh) ? Number(data?.thigh) : 0,
    };
    await dispatch(saveUserStats(payload));
    dispatch(getUserStats());
    dispatch(getWeights());
    dispatch(getUserProfile());
    setDate(moment(new Date()).format("MM/DD/YYYY"));
    reset();
  };

  const updateData = async (data) => {
    const payload = {
      goal_weight: Number(data?.targetedWeight).toFixed(2),
    };

    setLoading(true);
    const res = await dispatch(updateUserData(payload));
    setLoading(false);

    if (res.status === "success") {
      dispatch(getUserProfile());
      resetUpdate();
      setSuccess("Saved!");

      setTimeout(() => {
        setSuccess("");
      }, [3000]);
    }
  };

  const updateDataStart = async (data) => {
    const payload = {
      start_weight: Number(data?.startWeight).toFixed(2),
    };

    setLoadingStart(true);
    const res = await dispatch(updateUserData(payload));
    setLoadingStart(false);

    if (res.status === "success") {
      dispatch(getUserProfile());
      dispatch(getWeights());
      // resetUpdate();
      setSuccessStart("Saved!");

      setTimeout(() => {
        setSuccessStart("");
      }, [3000]);
    }
  };

  const handleDelete = async () => {
    if (selectedData) {
      setLoading(true);
      await dispatch(deleteUserStats(selectedData?.id));
      dispatch(getUserStats());
      dispatch(getWeights());
      dispatch(getUserProfile());
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loginUser?.goalWeight)
      setValue("targetedWeight", loginUser?.goalWeight);
    if (loginUser?.startWeight)
      setValueCurrent("startWeight", loginUser?.startWeight);
  }, [loginUser]);

  useEffect(() => {
    if (sessionStorage.getItem("todaysWeight")) {
      setValueForm("weight", sessionStorage.getItem("todaysWeight"));
      sessionStorage.removeItem("todaysWeight");
    }
  }, []);

  const calculateCurrentDay = (current, plan) => {
    const diff = moment(current).diff(plan, "days");

    if (diff == 0 || diff) {
      return `(Day ${diff + 1})`;
    } else {
      return "";
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      {loginUser?.isClient ? (
        <div className="card shadow-none main-card">
          <div className="card-body">
            <div className="row g-3 flex-reverse-mob">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header pb-3">
                    <h5 className="fw-semibold">Ready for Today’s Entry</h5>
                    <p className="text-body">
                      It only takes a few seconds to get a measurment
                    </p>
                  </div>
                  <div className="card-body text-body">
                    <form
                      className="d-flex flex-column justify-content-between h-100"
                      onSubmit={handleSubmit(saveData)}>
                      <div>
                        <div className="row mb-3 align-items-center">
                          <label className="col-4 fb-label">Date</label>
                          <div className="col-8">
                            <DateInput
                              date={date}
                              setDate={setDate}
                            />
                          </div>
                        </div>
                        <FormTextField
                          label="Weight (lbs)"
                          fieldName="weight"
                          register={register}
                          errors={errors}
                        />
                        <FormTextField
                          label="How do you feel?"
                          fieldName="feeling"
                          register={register}
                          errors={errors}
                        />

                        <div className="row g-3 mb-3 mt-1">
                          <label className="col-4 mt-sm-3 fb-label">
                            Weekly Measurments (inches)
                            <br />
                            <span className="text-muted">Optional</span>
                          </label>
                          <div className="col-8">
                            <div className="row mb-3 align-items-center">
                              <FormTextField
                                divClassName="col-6"
                                innerDivClassName="col-sm-12"
                                label="Chest"
                                fieldName="chest"
                                register={register}
                                errors={errors}
                              />
                              <FormTextField
                                divClassName="col-6"
                                innerDivClassName="col-sm-12"
                                label="Waist"
                                fieldName="waist"
                                register={register}
                                errors={errors}
                              />
                            </div>
                            <div className="row mb-3 align-items-center">
                              <FormTextField
                                divClassName="col-6"
                                innerDivClassName="col-sm-12"
                                label="Bicep"
                                fieldName="bicep"
                                register={register}
                                errors={errors}
                              />
                              <FormTextField
                                divClassName="col-6"
                                innerDivClassName="col-sm-12"
                                label="Thigh"
                                fieldName="thigh"
                                register={register}
                                errors={errors}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center mb-4">
                        <div className="col-sm-5">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn br-10 w-100">
                            {loading ? "Saving" : "Save"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body text-body">
                    <h5
                      style={{ color: "#1e2a42", marginBottom: 0 }}
                      className="fw-semibold">
                      Start Weight (lbs)
                    </h5>
                    <form
                      className="d-flex flex-column h-100"
                      onSubmit={handleSubmitUpdateCurrent(updateDataStart)}>
                      <div className="row align-items-end margin-top-mobile">
                        <FormTextField
                          divClassName="col-md-7"
                          innerDivClassName="col-sm-12"
                          labelClassName="col-12 fb-label pb-2"
                          label=""
                          fieldName="startWeight"
                          register={registerUpdateCurrent}
                          errors={errorsUpdateCurrent}
                        />
                        <div className="col-md-1"></div>
                        <div className="col-md-4 mb-2 margin-top-mobile">
                          <button
                            disabled={loadingStart}
                            type="submit"
                            className="btn br-10 w-100">
                            {loadingStart ? "Saving" : "Save"}
                          </button>
                        </div>
                        <p
                          style={{
                            marginTop: 10,
                            color: "green",
                            fontSize: "14px",
                            paddingBottom: -20,
                          }}>
                          {successStart ? successStart : ""}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body text-body">
                    <h5
                      style={{ color: "#1e2a42", marginBottom: 0 }}
                      className="fw-semibold">
                      Goal Weight (lbs)
                    </h5>
                    <form
                      className="d-flex flex-column h-100"
                      onSubmit={handleSubmitUpdate(updateData)}>
                      <div className="row align-items-end margin-top-mobile">
                        <FormTextField
                          divClassName="col-md-7"
                          innerDivClassName="col-sm-12"
                          labelClassName="col-12 fb-label pb-2"
                          label=""
                          fieldName="targetedWeight"
                          register={registerUpdate}
                          errors={errorsUpdate}
                        />
                        <div className="col-md-1"></div>
                        <div className="col-md-4 mb-2 margin-top-mobile">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn br-10 w-100">
                            {loading ? "Saving" : "Save"}
                          </button>
                        </div>
                        <p
                          style={{
                            marginTop: 10,
                            color: "green",
                            fontSize: "14px",
                            paddingBottom: -20,
                          }}>
                          {success ? success : ""}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-header pb-3">
                    <h5 className="fw-semibold">Previous Entries</h5>
                    <p className="text-body">
                      Take a look back and see what you’ve been doing
                    </p>
                  </div>
                  <div
                    className="card-body text-body state-scroll"
                    id="state-scroll"
                    style={{ backgroundColor: "", height: "100%" }}>
                    {userStats?.map((value) => (
                      <div className="card bg-transparent border mb-4">
                        <div className="card-body">
                          <div
                            style={{
                              position: "absolute",
                              display: "flex",
                              justifyContent: "row",
                              right: 10,
                            }}>
                            <svg
                              onClick={() => {
                                setIsOpen(true);
                                setSelectedData(value);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24">
                              <path
                                fill="#088eac"
                                d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                              />
                              <path
                                fill="#088eac"
                                d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                              />
                            </svg>
                            <svg
                              onClick={() => {
                                setShowCofirm(true);
                                setSelectedData(value);
                              }}
                              style={{
                                cursor: "pointer",
                                marginLeft: 15,
                              }}
                              width="18"
                              height="18"
                              fill="red"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512">
                              <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                            </svg>
                          </div>

                          <h6 className="card-title semi text-1e">
                            {new Date(value.date)?.toDateString()}{" "}
                            <span style={{ color: "#088eac", fontWeight: 900 }}>
                              {calculateCurrentDay(
                                value?.date,
                                loginUser?.planStartedOn,
                              )}
                            </span>
                          </h6>
                          <div className="row g-3">
                            <div className="col-md-6">
                              <div className="text-muted mb-1">
                                Weight (lbs)
                              </div>
                              <div className="text-1e">
                                {value?.weight?.toFixed(2)}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="text-muted mb-1">
                                Measurements (inches)
                              </div>
                              <div className="text-1e">
                                <span className="pe-2">
                                  C :{" "}
                                  {value?.chest
                                    ? value?.chest?.toFixed(2)
                                    : "-"}
                                </span>
                                <span className="pe-2">
                                  W :{" "}
                                  {value?.waist
                                    ? value?.waist?.toFixed(2)
                                    : "-"}
                                </span>
                                <br />
                                <span className="pe-2">
                                  B :{" "}
                                  {value?.bicep
                                    ? value?.bicep?.toFixed(2)
                                    : "-"}
                                </span>
                                <span>
                                  T :{" "}
                                  {value?.thigh
                                    ? value?.thigh?.toFixed(2)
                                    : "-"}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="text-muted mb-1">
                                How did you feel?
                              </div>
                              <div className="text-1e">
                                {capitalizeFirstLetter(value?.feeling)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <p
            style={{
              fontSize: 18,
              paddingTop: 45,
              height: 120,
              textAlign: "center",
            }}
            className="card-header pb-3 fw-semibold">
            Content will be displayed here once your program begins.
          </p>
        </div>
      )}

      <UpdateStats
        modalIsOpen={open}
        setIsOpen={setIsOpen}
        selectedData={selectedData}
        closeModal={() => setIsOpen(false)}
      />

      <ConfirmationModal
        modalIsOpen={showConfirm}
        setIsOpen={setShowCofirm}
        onClickHandler={handleDelete}
        isLoading={loading}
        btnClicked={6}
        closeModal={() => {
          setShowCofirm(false);
        }}
        key={6}
      />
    </div>
  );
};

export default DailyStats;
