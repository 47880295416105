import React from "react";

const FourOFour = ({ history }) => {
  return (
    <div className="container-xxl container-p-y">
      <div className="misc-wrapper">
        <h2 className="mb-4 mx-2 bold">Page Not Found :(</h2>
        <p className="mb-4 mx-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>
        <a
          onClick={() => history.push("/breakthrough/dashboard")}
          className="btn btn-primary">
          Back to home
        </a>
        <div className="mt-3">
          <img src="assets/img/404.png" width="500" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default FourOFour;
