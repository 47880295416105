import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import StarRatingComponent from "react-star-rating-component";
import { addRating, getRatings } from "../store/actions/dashboardAction";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: 400,
  },

  overlay: {
    zIndex: 100000,
  },
};

const AddRatings = ({ modalIsOpen, afterOpenModal, closeModal, receipeId }) => {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { loginUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const saveData = async () => {
    const payload = {
      user_id: loginUser?.id,
      recipe_id: receipeId,
      rating: value,
    };
    setIsLoading(true);
    await dispatch(addRating(payload));
    await dispatch(getRatings(receipeId));
    setIsLoading(false);
    closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold">Add Rating</h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 20,
          }}>
          <h5
            style={{
              fontSize: 20,
              textAlign: "center",
              fontWeight: "600",
              color: "#333",
              marginBottom: 10,
            }}>
            My Rating:
          </h5>
          <StarRatingComponent
            emptyStarColor={"grey"}
            name="rating"
            starColor="#088eac"
            starCount={5}
            value={value}
            onStarClick={(val) => setValue(val)}
            editing={true}
          />

          <button
            disabled={isLoading}
            className="btn w-50 btn-lg br-10 mt-4"
            onClick={saveData}>
            {isLoading ? "Saving" : "Save"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddRatings;
