import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormTextField from "../forms/FormTextField";
import moment from "moment";
import {
  getUserStats,
  getWeights,
  updateUserStats,
} from "../store/actions/dashboardAction";
import { getUserProfile } from "../store/actions/authAction";
import DateInput from "../forms/DateInput";

const schema = yup.object({
  weight: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(/^[0-9]\d{0,9}(\.\d{1,3})?%?$/, "Invalid Value"),
  feeling: yup.string().trim().required("Cannot be empty"),
  chest: yup.string().trim(),
  waist: yup.string().trim(),
  thigh: yup.string().trim(),
  bicep: yup.string().trim(),
});

const UpdateStatsUI = ({ closeModal, selectedData }) => {
  const [date, setDate] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (selectedData) {
      setDate(moment(new Date(selectedData?.date)).format("MM/DD/YYYY"));
      setValue("weight", selectedData?.weight);
      setValue("feeling", selectedData?.feeling);
      setValue("chest", selectedData?.chest ? selectedData?.chest : "");
      setValue("waist", selectedData?.waist ? selectedData?.waist : "");
      setValue("thigh", selectedData?.thigh ? selectedData?.thigh : "");
      setValue("bicep", selectedData?.bicep ? selectedData?.bicep : "");
    }
  }, [selectedData]);

  const saveData = async (data) => {
    const payload = {
      id: selectedData?.id,
      weight: +data?.weight,
      feeling: data?.feeling,
      date: new Date(date).getTime(),
      chest: Number(data?.chest) ? Number(data?.chest) : 0,
      waist: Number(data?.waist) ? Number(data?.waist) : 0,
      bicep: Number(data?.bicep) ? Number(data?.bicep) : 0,
      thigh: Number(data?.thigh) ? Number(data?.thigh) : 0,
    };
    await dispatch(updateUserStats(payload));
    dispatch(getUserStats());
    dispatch(getWeights());
    dispatch(getUserProfile());
    closeModal();
  };

  return (
    <div
      className="modal-content update-stats-modal"
      style={{ overflowY: "auto", overflowX: "hidden", paddingTop: 40 }}>
      <div className="card-body text-body">
        <form
          className="d-flex flex-column justify-content-between h-100"
          onSubmit={handleSubmit(saveData)}>
          <div>
            <div className="row mb-3 align-items-center">
              <label className="col-4 fb-label">Date</label>
              <div className="col-8">
                <DateInput
                  date={date}
                  setDate={setDate}
                />
              </div>
            </div>
            <FormTextField
              label="Weight (lbs)"
              fieldName="weight"
              register={register}
              errors={errors}
            />
            <FormTextField
              label="How do you feel?"
              fieldName="feeling"
              register={register}
              errors={errors}
            />

            <div className="row g-3 mb-3 mt-1">
              <label className="col-4 mt-sm-3 fb-label">
                Weekly Measurments (inches)
                <br />
                <span className="text-muted">Optional</span>
              </label>
              <div className="col-8">
                <div className="row mb-3 align-items-center">
                  <FormTextField
                    divClassName="col-6"
                    innerDivClassName="col-sm-12"
                    label="Chest"
                    fieldName="chest"
                    register={register}
                    errors={errors}
                  />
                  <FormTextField
                    divClassName="col-6"
                    innerDivClassName="col-sm-12"
                    label="Waist"
                    fieldName="waist"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="row mb-3 align-items-center">
                  <FormTextField
                    divClassName="col-6"
                    innerDivClassName="col-sm-12"
                    label="Bicep"
                    fieldName="bicep"
                    register={register}
                    errors={errors}
                  />
                  <FormTextField
                    divClassName="col-6"
                    innerDivClassName="col-sm-12"
                    label="Thigh"
                    fieldName="thigh"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mb-4">
            <div className="col-sm-5">
              <button
                disabled={loading}
                type="submit"
                className="btn br-10 w-100">
                {loading ? "Updating" : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateStatsUI;
