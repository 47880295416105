// base url
export const URL = "https://dashboard-beta.alulawellness.com/breakthroughapi";
// export const URL = "http://localhost:3004/breakthroughapi";

// apis
export const LOGIN = "login";
export const GET_PROFILE = "user/profile";
export const USER_STATS = "user/stats";
export const WEEKLY_PHOTOS = "user/weeklyPhotos";
export const GET_MEMBERS = "user/coach/members";
export const GET_MEMBERS_ADMIN = "user/admin/members";
export const SAVE_MEMBER_UPDATE = "user/admin/member";
export const GET_MEMBER_INFO = "user/coach/member";
export const DAILY_NOTES = "plan/dailyNotes";
export const POSTS = "/post";
export const TESTIMONIAL = "/testimonial";
export const BASICS = "basics";
export const ACTIONS = "actions";
export const RECEIPE = "recipe";
export const RECEIPE_SEARCH = "recipe-search";
export const POSTS_REACT = "/post/react";
export const POST_COMMENT = "/post/comment";
export const REMOVE_REACTION = "/post/removeReaction";
export const UPDATE_PRIVACY = "/post/privacy";
export const DAILY_TITLES = "/plan/dailyTitles";
export const GET_WEIGHTS = "/user/weights";
export const DELETE_IMG = "/user/weeklyPhotos/delete";
