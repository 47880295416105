import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/actions/authAction";
import { getLocalData } from "../../utils/functions";
import {
  addNotificationStatus,
  getAdminUser,
  getLinks,
  getNotificationStatus,
  getNotifications,
  setDashboardData,
  updateNotificationStatus,
} from "../../store/actions/dashboardAction";
import moment from "moment";

const Header = ({ loginUser, history, setShowSidebar }) => {
  const [showDropDown, setShowDropdown] = useState(false);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);

  const dispatch = useDispatch();

  const userType = getLocalData("userType");

  const { notifications, isReadAll } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getLinks());
  }, []);

  useEffect(() => {
    if (loginUser?.coach?.id) {
      dispatch(getAdminUser(loginUser?.coach?.id));
      // dispatch(getAdminCoach(loginUser?.coach?.id));
    }

    if (loginUser?.isClient) {
      dispatch(getNotifications());
      dispatch(getNotificationStatus());
    }
  }, [loginUser]);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("mo_jwt_token");
    dispatch(setUserData({ token: null, loginUser: null }));
    dispatch(setDashboardData({ userWeights: null, notifications: null }));

    history.push("/login");
  };

  const notificationIconClickHandler = async () => {
    const res = await dispatch(getNotificationStatus());

    if (res?.id) {
      dispatch(updateNotificationStatus({ type: "read" }));
    } else {
      dispatch(addNotificationStatus());
    }
  };

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar "
      style={{
        backgroundColor: "",
        padding: 0,
        marginTop: 0,
        marginRight: 20,
      }}>
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a
          className="nav-item nav-link p-0 me-xl-4"
          role="button"
          onClick={() => setShowSidebar(true)}>
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center justify-content-between"
        id="navbar-collapse"
        style={{ backgroundColor: "" }}>
        <div className="col-md-4 col-6">
          <div className="d-block d-xl-none">
            <a
              role="button"
              onClick={() => history.push("/dashboard")}
              className="app-brand-link">
              <img
                src="assets/img/logo.png"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
        <h4 className="col-md-4 col-6 text-uppercase text-md-center fw-semi text-dark mb-0 header-top-text header-text-center">
          {window.location.pathname === "/dashboard" ||
          window.location.pathname === "/coaches" ||
          window.location.pathname === "/members" ||
          window.location.pathname === "/restartjourney"
            ? userType === "admin"
              ? "Breakthrough M2 Admin"
              : ` Good Morning ${
                  loginUser?.name ? loginUser?.name?.split(" ")[0] : ""
                }`
            : window.location.pathname === "/daily-stats"
            ? "Daily Stats"
            : window.location.pathname === "/weekly-photos"
            ? "Weekly Photos"
            : window.location.pathname === "/testimonials"
            ? "Testimonials"
            : window.location.pathname === "/recipes"
            ? "Recipes"
            : window.location.pathname === "/kudos-board"
            ? "Kudos Board"
            : window.location.pathname === "/referral"
            ? "Referrals"
            : window.location.pathname === "/profile"
            ? "Profile"
            : window.location.pathname === "/journey"
            ? "Journey"
            : window.location.pathname === "/bonusVideos"
            ? "Bonus Videos"
            : window.location.pathname === "/photo-stamps"
            ? "Photo Stamps"
            : ""}
        </h4>
        <ul
          className="col-md-4 col-6 navbar-nav flex-row align-items-center justify-content-end"
          style={{ backgroundColor: "" }}>
          {userType !== "admin" && (
            <>
              <li className="nav-item">
                <a
                  href={
                    loginUser?.isClient
                      ? `https://beta.alulawellness.com/shop?mo_jwt_token=${getLocalData(
                          "mo_jwt_token",
                        )}`
                      : `https://beta.alulawellness.com/product-category/breakthrough-m2/starter-packages`
                  }
                  target="_blank"
                  className="nav-link btn-circle">
                  <i className="bx bxs-store"></i>
                </a>
              </li>

              <li className="nav-item navbar-dropdown dropdown-notification dropdown">
                <a
                  className={`nav-link dropdown-toggle hide-arrow btn-circle ${
                    notificationDropdown ? "show" : ""
                  }`}
                  role="button"
                  onClick={() => {
                    setNotificationDropdown(!notificationDropdown);
                    setNotificationOpened(true);
                    setShowDropdown(false);
                    notificationIconClickHandler();
                  }}
                  data-bs-toggle="dropdown">
                  <i
                    className={
                      isReadAll || !loginUser?.isClient
                        ? "bx bxs-bell"
                        : "bx bxs-bell bx-tada"
                    }></i>
                  {loginUser?.isClient && (
                    <div className="notify">
                      <span className={isReadAll ? "" : "heartbit"}></span>{" "}
                      <span className={isReadAll ? "" : "point"}></span>{" "}
                    </div>
                  )}
                </a>
                <ul
                  className={`dropdown-menu dropdown-menu-end py-0 notification-dropdown ${
                    notificationDropdown ? "show" : ""
                  }`}>
                  <li className="dropdown-menu-header border-bottom">
                    <div className="dropdown-header d-flex align-items-center py-3">
                      <h5 className="text-body mb-0 me-auto">Notifications</h5>
                      <div className="dropdown-notifications-all text-body">
                        <i className="bx fs-4 bx-envelope-open"></i>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown-notifications-list message-center theme-scrollbar">
                    <ul className="list-group list-group-flush">
                      {notifications?.map((val) => (
                        <li
                          style={{ cursor: "pointer" }}
                          className="list-group-item list-group-item-action dropdown-notifications-item"
                          onClick={() => {
                            history.push(`/kudos-board`);
                            setNotificationDropdown(false);
                          }}>
                          <div className="d-flex">
                            {/* <div className="flex-shrink-0 me-3">
                            <div className="avatar">
                              <span className="avatar-initial rounded-circle bg-label-primary">
                                CF
                              </span>
                            </div>
                          </div> */}
                            <div className="flex-grow-1">
                              {/* <h6 className="mb-1 semi">Charles Franklin</h6> */}
                              <p className="mb-0 small">{val?.notification}</p>
                              <small className="text-muted">
                                {moment(new Date(val?.created_at)).fromNow()}
                              </small>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {!notifications && (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 20,
                        }}>
                        No notifications yet
                      </p>
                    )}
                  </li>
                  {/* {notifications && (
                    <li className="dropdown-menu-footer border-top">
                      <a
                        role="button"
                        className="dropdown-item d-flex justify-content-center p-3">
                        View all notifications
                      </a>
                    </li>
                  )} */}
                </ul>
              </li>
            </>
          )}
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              className={`nav-link dropdown-toggle hide-arrow btn-circle ${
                showDropDown ? "show" : ""
              }`}
              role="button"
              onClick={() => {
                setShowDropdown(!showDropDown);
                setNotificationDropdown(false);
              }}
              data-bs-toggle="dropdown">
              <i className="bx bxs-user"></i>
            </a>
            <ul
              className={`dropdown-menu dropdown-menu-end user-dropdown ${
                showDropDown ? "show" : ""
              }`}>
              {userType !== "admin" && (
                <li>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/profile");
                      setShowDropdown(false);
                    }}>
                    <div className="dropdown-item">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar">
                            <img
                              src={getLocalData("profilePic")}
                              alt
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <span className="fw-bold d-block">
                            {loginUser?.name}
                          </span>
                          <small className="text-muted">
                            {loginUser?.username}
                          </small>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              )}
              <li>
                <div className="dropdown-divider"></div>{" "}
              </li>
              {/* {userType !== "admin" && (
                <li>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={() => history.push("/dashboard")}>
                    <i className="bx bx-user me-2"></i>
                    <span className="align-middle">Profile</span>
                  </a>
                </li>
              )} */}

              <li>
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={logoutHandler}>
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
