import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormDatePicker from "../../../forms/FormDatePicker";
import {
  deleteCoachNotesAction,
  getAdminMembers,
  getCoachMembers,
  getUserNotesById,
  getUserStatsById,
  saveCoachNotes,
  saveUserPlaneStartsOn,
  updateUserData,
} from "../../../store/actions/dashboardAction";
import EditCoach from "../../../modals/edit-coach";
import CoachDashboard from "./coach-dashboard";
import { capitalizeFirstLetter, getLocalData } from "../../../utils/functions";
import ConfirmationModal from "../../../modals/confirmation-modal";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextField from "../../../forms/FormTextField";
import EditDate from "../../../modals/edit-date";
import SendEmailModal from "../../../modals/send-email";
import EditNotes from "../../../modals/edit-notes";
import EditDetails from "../../../modals/edit-details";

const totalStep = [1, 2, 3, 4];
const steps = {
  step1: [1, 2],
  step2: [3, 4, 5, 6, 8, 15, 22],
  step3: [35],
  step4: [39, 41, 43, 46, 49, 51, 53, 57, 61, 62],
};

const updateSchema = yup.object({
  coachNotes: yup.string().trim().required("Cannot be empty"),
});

const MemberProfile = ({
  userInfo,
  setPageType,
  userLocal,
  coaches,
  getUserInfo,
  setLocalData,
  failed,
}) => {
  const mo_jwt_token = getLocalData("mo_jwt_token");

  const dispatch = useDispatch();
  const [editCoach, setEditCoach] = useState(false);
  const [editNotes, setEditNotes] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [editDate, setEditDate] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [detailType, setDetailsType] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [btnClicked, setBtnClicked] = useState(null);
  const [showConfirm, setShowCofirm] = useState(false);
  const [userStats, setUserStats] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);
  const [dateType, setDateType] = useState("");
  const [sendEmail, setSendEmail] = useState("");

  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    setValue,
    reset: resetUpdate,
    formState: { errors: errorsUpdate },
  } = useForm({
    resolver: yupResolver(updateSchema),
  });

  useEffect(() => {
    getUserStats();
    getUserNotes();
  }, []);

  useEffect(() => {
    if (userInfo?.coachNotes) {
      setValue("coachNotes", userInfo?.coachNotes);
    }
  }, [userInfo]);

  const getUserStats = async () => {
    setLoadingStats(true);
    const res = await dispatch(getUserStatsById(userLocal?.id));

    if (res) {
      setUserStats(res);
    }
    setLoadingStats(false);
  };

  const getUserNotes = async () => {
    setLoadingStats(true);
    const res = await dispatch(getUserNotesById(userLocal?.id));

    if (res?.data) {
      setUserNotes(res.data);
    }
    setLoadingStats(false);
  };

  const userType = getLocalData("userType");

  const dateChangeHanlder = async () => {
    const payload = {
      id: userInfo.id,
    };

    if (btnClicked === 3) {
      payload["planStartedOn"] = "13-13-13";
    }
    if (btnClicked === 31) {
      payload["lowCalorieDate"] = "13-13-13";
    }
    if (btnClicked === 32) {
      payload["transitionDate"] = "13-13-13";
    }

    setLoading(true);
    const res = await dispatch(saveUserPlaneStartsOn(payload));
    await getUserInfo(userInfo);
    setLoading(false);

    if (res.status === "success") {
      if (userType === "admin") {
        dispatch(getAdminMembers());
      } else {
        dispatch(getCoachMembers());
      }
    }
  };

  const updateData = async (val) => {
    const payload = {
      userId: userInfo?.id,
      value: val,
    };

    setLoading(true);
    await dispatch(updateUserData(payload));
    setLoading(false);
  };

  const addData = async (data) => {
    const payload = {
      ...data,
      useremail: userInfo?.useremail,
      id: userLocal.id,
    };

    setLoading(true);
    await dispatch(saveCoachNotes(payload));
    setLoading(false);
    resetUpdate();
    getUserNotes();
  };

  const deleteNotes = async () => {
    const payload = {
      coachNotes: selectData?.note,
      useremail: userInfo?.useremail,
      id: userLocal.id,
      noteId: selectData?.id,
    };

    setLoading(true);
    await dispatch(deleteCoachNotesAction(payload));
    await getUserNotes();
    setLoading(false);
  };

  return (
    <>
      {coaches ? (
        <CoachDashboard
          coachId={userLocal?.id}
          setLocalData={setLocalData}
          coaches={!userLocal?.id}
          key={userLocal?.id}
        />
      ) : (
        <div className="container-xxl flex-grow-1 container-p-b">
          <div className="card shadow-none main-card">
            <div className="card-body">
              <div className="col-md-12 text-md-start back-btn-box">
                <a
                  onClick={() => setPageType("listing")}
                  className="back-btn">
                  &lsaquo; Back To Listing
                </a>
              </div>
              <div className="row g-3">
                {!coaches && (
                  <div className="col-md-12">
                    {!userInfo ? (
                      <div className="loading-text">
                        <p>{!failed ? "Loading..." : "No Data"} </p>
                      </div>
                    ) : (
                      <>
                        <div className="row g-3 mb-3 d-flex justify-content-center">
                          <div className="col-md-12 row mt-4 member-profile-card">
                            <h4 className="profile-heading">INFO</h4>

                            <div className="col-md-4">
                              <p className="tag">Name</p>
                              <h4 className="mb-1 tag-val">
                                {userLocal?.name}
                              </h4>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Email</p>
                              <h4 className="mb-1 tag-val">
                                {userLocal?.email}
                              </h4>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Phone</p>
                              <h4 className="mb-1 tag-val">
                                {userLocal?.phone || "-"}
                              </h4>
                            </div>
                            <div className="col-md-4 mt-4">
                              <p className="tag">CUSTOMER ID</p>
                              <p className="tag-val">{userInfo?.id}</p>
                            </div>
                            <div className="col-md-4 mt-4">
                              <p className="tag">Medical Form</p>
                              {userInfo?.medical_form?.pdf_link ? (
                                <a
                                  style={{ marginTop: -30 }}
                                  href={userInfo?.medical_form?.pdf_link}
                                  target="_blank">
                                  <p className="tag-val">Medical Form PDF</p>{" "}
                                </a>
                              ) : (
                                <p className="tag-val">-</p>
                              )}
                            </div>

                            <div className="col-md-4 mt-4">
                              <p className="tag">Last Login</p>
                              <p className="tag-val">
                                {userLocal?.lastLogin
                                  ? moment(userLocal?.lastLogin)?.format(
                                      "MM/DD/YYYY",
                                    )
                                  : "-"}
                              </p>
                            </div>

                            <div className="col-md-4">
                              <svg
                                onClick={() => setEditCoach(true)}
                                style={{
                                  position: "absolute",
                                  marginLeft: 50,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <span className="tag">Coach</span>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}>
                                {userInfo?.coach?.id ? (
                                  <img
                                    style={{
                                      width: 40,
                                      marginTop: 5,
                                      display: "block",
                                    }}
                                    src={userInfo?.coach?.profilePic}
                                  />
                                ) : (
                                  ""
                                )}
                                <span
                                  className="tag-val"
                                  style={{ marginTop: 5, display: "block" }}>
                                  {userInfo?.coach?.name
                                    ? userInfo?.coach?.name
                                    : "No Coach"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <svg
                                onClick={() => {
                                  setEditDetails(true);
                                  setDetailsType("age");
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 40,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Age</p>
                              <p className="tag-val">
                                {userInfo?.age
                                  ? userInfo?.age
                                  : userInfo?.bm2_age
                                  ? userInfo?.bm2_age
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-4 mt-2">
                              <svg
                                onClick={() => {
                                  setEditDetails(true);
                                  setDetailsType("height");
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 60,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Height</p>
                              <p className="tag-val">
                                {userInfo?.height
                                  ? userInfo?.height
                                  : userInfo?.bm2_height
                                  ? userInfo?.bm2_height
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-4 mt-2">
                              <svg
                                onClick={() => {
                                  setEditDetails(true);
                                  setDetailsType("medical");
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 110,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Medical Issues</p>
                              <p className="tag-val">
                                {userInfo?.medicalIssues
                                  ? userInfo?.medicalIssues
                                  : userInfo?.bm2_medical_issues
                                  ? userInfo?.bm2_medical_issues
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-4 mt-2">
                              <svg
                                onClick={() => {
                                  setEditDetails(true);
                                  setDetailsType("medications");
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 150,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Current Medications</p>
                              <p className="tag-val">
                                {userInfo?.meds
                                  ? userInfo?.meds
                                  : userInfo?.bm2_meds
                                  ? userInfo?.bm2_meds
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-12 row mt-4 member-profile-card">
                            <h4 className="profile-heading">PROGRAM</h4>
                            <div className="col-md-4">
                              <svg
                                onClick={() => {
                                  setDateType("started-date");
                                  setEditDate(true);
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 100,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Start Date</p>
                              <p className="tag-val">
                                {userInfo?.planStartedOn
                                  ? moment(userInfo?.planStartedOn)?.format(
                                      "MM/DD/YYYY",
                                    )
                                  : "-"}
                              </p>
                              {/* <FormDatePicker
                                id={userInfo?.id}
                                planStartedOn={userInfo?.planStartedOn}
                                planStartedOnForm={true}
                              /> */}
                            </div>
                            <div className="col-md-4">
                              <svg
                                onClick={() => {
                                  setEditDate(true);
                                  setDateType("low-calorie-date");
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 135,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Low Calorie Date</p>
                              <p className="tag-val">
                                {userInfo?.lowCalorieDate
                                  ? moment(userInfo?.lowCalorieDate)?.format(
                                      "MM/DD/YYYY",
                                    )
                                  : "-"}
                              </p>
                              {/* <FormDatePicker
                                id={userInfo?.id}
                                lowCalorieDate={userInfo?.lowCalorieDate}
                                lowCalorieDateForm={true}
                              /> */}
                            </div>
                            <div className="col-md-4">
                              <svg
                                onClick={() => {
                                  setEditDate(true);
                                  setDateType("transition-date");
                                }}
                                style={{
                                  position: "absolute",
                                  marginLeft: 120,
                                  cursor: "pointer",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24">
                                <path
                                  fill="#088eac"
                                  d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                />
                                <path
                                  fill="#088eac"
                                  d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                />
                              </svg>
                              <p className="tag">Transition Date</p>
                              <p className="tag-val">
                                {userInfo?.transitionDate
                                  ? moment(userInfo?.transitionDate)?.format(
                                      "MM/DD/YYYY",
                                    )
                                  : "-"}
                              </p>
                              {/* <FormDatePicker
                                id={userInfo?.id}
                                transitionDate={userInfo?.transitionDate}
                                transitionDateForm={true}
                              /> */}
                            </div>

                            <div className="col-md-4 mt-2">
                              <p className="tag">Status</p>
                              <p className="tag-val">Active</p>
                            </div>
                            <div className="col-md-4 mt-2">
                              <p className="tag">Days On Program</p>
                              <p className="tag-val">
                                {userInfo?.completedDays}
                              </p>
                            </div>
                            <div className="col-md-4 mt-2">
                              <p className="tag">Current Step</p>
                              <p className="tag-val">
                                {totalStep?.map((val) =>
                                  steps[`step${val}`]?.includes(
                                    userInfo?.contentDay,
                                  )
                                    ? val
                                    : "",
                                )}
                                {!userInfo?.contentDay ? "-" : ""}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Days Into Step</p>
                              <p className="tag-val">
                                {userInfo?.completedDays}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Percent</p>
                              <p className="tag-val">
                                {userInfo?.progressPercent}%
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Weight Lost To Date</p>
                              <p className="tag-val">
                                {userInfo?.startWeight &&
                                userInfo?.currentWeight
                                  ? userInfo?.startWeight -
                                    userInfo?.currentWeight
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Starting Weight</p>
                              <p className="tag-val">
                                {userInfo?.startWeight
                                  ? userInfo?.startWeight
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Current Weight</p>
                              <p className="tag-val">
                                {userInfo?.currentWeight}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="tag">Goal Weight</p>
                              <p className="tag-val">
                                {userInfo?.goalWeight
                                  ? userInfo?.goalWeight
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 row mt-4 member-profile-card">
                            <h4 className="profile-heading">ACTIONS</h4>
                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() => {
                                  setBtnClicked(1);
                                  // updateData(0);
                                  setShowCofirm(true);
                                }}
                                className="btn-admin btn-outline-danger">
                                BACK TO STEP 2
                              </button>
                              <p className="btn-bottom-text">
                                Put a customer back into low calorie (Step2)
                              </p>
                            </div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() => {
                                  setBtnClicked(2);
                                  // updateData(1);
                                  setShowCofirm(true);
                                }}
                                className="btn-admin btn-outline-danger">
                                BEGIN TRANSITION
                              </button>
                              <p className="btn-bottom-text">
                                Put a customer into transition{" "}
                              </p>
                            </div>

                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() => {
                                  // dateChangeHanlder();
                                  setShowCofirm(true);
                                  setBtnClicked(3);
                                }}
                                className="btn-admin btn-outline-danger">
                                REMOVE START DATE
                              </button>
                              <p className="btn-bottom-text">
                                Strips a user of their start date
                              </p>
                            </div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() => {
                                  // dateChangeHanlder();
                                  setShowCofirm(true);
                                  setBtnClicked(31);
                                }}
                                style={{ fontSize: "0.9rem" }}
                                className="btn-admin btn-outline-danger">
                                REMOVE LOW CALORIE DATE
                              </button>
                              <p className="btn-bottom-text">
                                Strips a user of their low calorie date
                              </p>
                            </div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() => {
                                  // dateChangeHanlder();
                                  setShowCofirm(true);
                                  setBtnClicked(32);
                                }}
                                style={{ fontSize: "0.95rem" }}
                                className="btn-admin btn-outline-danger">
                                REMOVE TRANSITION DATE
                              </button>
                              <p className="btn-bottom-text">
                                Strips a user of their transition date
                              </p>
                            </div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() => {
                                  setSendEmail(true);
                                }}
                                style={{ fontSize: "0.95rem" }}
                                className="btn-admin btn-outline-danger">
                                SEND EMAIL TEMPLATE
                              </button>
                              <p className="btn-bottom-text">
                                Send a premade email template from AIOTB
                              </p>
                            </div>

                            <h4 className="profile-heading">LINKS</h4>
                            <div className="col-md-4">
                              <button
                                type="button"
                                className="btn-admin btn-outline-info"
                                onClick={() =>
                                  window.open(
                                    `https://beta.alulawellness.com/wp-admin/edit.php?s&post_status=all&post_type=shop_order&action=-1&m=0&shop_order_wc_pos_order_type&shop_order_wc_pos_filter_register&_customer_user=${userInfo?.id}&_shop_order_pip_print_status&filter_action=Filter&paged=1&action2=-1&mo_jwt_token=${mo_jwt_token}`,
                                  )
                                }>
                                ORDERS
                              </button>
                              <p className="btn-bottom-text">
                                View this customer's orders
                              </p>
                            </div>

                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() =>
                                  window.open(
                                    `https://beta.alulawellness.com/wp-admin/user-edit.php?user_id=${userInfo?.id}&mo_jwt_token=${mo_jwt_token}`,
                                  )
                                }
                                className="btn-admin btn-outline-info">
                                ACCOUNTS
                              </button>
                              <p className="btn-bottom-text">
                                Manage customer's WordPress account
                              </p>
                            </div>

                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={() =>
                                  window.open(
                                    `https://beta.alulawellness.com/my-account/?view=member&member_id=${userInfo?.id}&mo_jwt_token=${mo_jwt_token}`,
                                  )
                                }
                                className="btn-admin btn-outline-info">
                                REFERRALS
                              </button>
                              <p className="btn-bottom-text">
                                Manage customer's credit referrals
                              </p>
                            </div>
                          </div>

                          <div className="col-md-12 row mt-4 member-profile-card">
                            <h4 className="profile-heading">COACH NOTES</h4>
                            <div className="col-md-6">
                              <form
                                className="d-flex flex-column h-100 col-md-10"
                                onSubmit={handleSubmitUpdate(addData)}>
                                <div className="row align-items-center">
                                  <FormTextField
                                    divClassName="col-md-12"
                                    innerDivClassName="col-sm-12"
                                    labelClassName="col-12 fb-label pb-2"
                                    label="Add Notes"
                                    fieldName="coachNotes"
                                    register={registerUpdate}
                                    errors={errorsUpdate}
                                  />
                                  <div
                                    className="col-md-12 mb-2 mt-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn br-10 w-50">
                                      {loading ? "Saving" : "Save"}
                                    </button>
                                  </div>
                                  <p
                                    style={{
                                      marginTop: 10,
                                      color: "green",
                                      fontSize: "14px",
                                      paddingBottom: -20,
                                    }}>
                                    {success ? success : ""}
                                  </p>
                                </div>
                              </form>
                            </div>
                            <div className="col-md-6">
                              {userNotes?.map((value) => (
                                <div className="card bg-transparent border mb-4">
                                  <div className="card-body">
                                    <div
                                      style={{
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "row",
                                        right: 10,
                                      }}>
                                      <span className="tooltip-side">
                                        {value?.coach_id !=
                                          getLocalData("userId") && (
                                          <span className="tooltiptext-member">
                                            Only Author can edit.
                                          </span>
                                        )}
                                        <svg
                                          onClick={() => {
                                            if (
                                              value?.coach_id ==
                                              getLocalData("userId")
                                            ) {
                                              setEditNotes(true);
                                              setSelectData(value);
                                            }
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24">
                                          <path
                                            fill="#088eac"
                                            d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                                          />
                                          <path
                                            fill="#088eac"
                                            d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                                          />
                                        </svg>
                                      </span>

                                      <span className="tooltip-side">
                                        {value?.coach_id !=
                                          getLocalData("userId") && (
                                          <span className="tooltiptext-member">
                                            Only Author can delete.
                                          </span>
                                        )}
                                        <svg
                                          onClick={() => {
                                            if (
                                              value?.coach_id ==
                                              getLocalData("userId")
                                            ) {
                                              setShowCofirm(true);
                                              setBtnClicked(7);
                                              setSelectData(value);
                                            }
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 15,
                                          }}
                                          width="18"
                                          height="18"
                                          fill="red"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 448 512">
                                          <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                                        </svg>
                                      </span>
                                    </div>

                                    <h6 className="card-title semi text-1e">
                                      {moment(
                                        new Date(value?.created_at),
                                      )?.format("MM/DD/YYYY h:mm a")}
                                      {"  "}
                                      <span
                                        style={{
                                          fontSize: 16,
                                          color: "#6d778e",
                                        }}>
                                        {value?.coachName}
                                      </span>
                                    </h6>
                                    <div className="col-md-12">
                                      <div className="text-1e">
                                        {value?.note
                                          ?.split("\n")
                                          ?.map((note) => (
                                            <span style={{ display: "block" }}>
                                              {note}
                                            </span>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="col-md-12 mt-4 member-profile-card">
                            <h4 className="profile-heading">DAILY STATS</h4>

                            <div
                              className="card-body text-body state-scroll"
                              id="state-scroll"
                              style={{ backgroundColor: "", height: "100%" }}>
                              {userStats?.map((value) => (
                                <div className="card bg-transparent border mb-4">
                                  <div className="card-body">
                                    <h6 className="card-title semi text-1e">
                                      {new Date(value.date)?.toDateString()}
                                    </h6>
                                    <div className="row g-3">
                                      <div className="col-md-4">
                                        <div className="text-muted mb-1">
                                          Weight (lbs)
                                        </div>
                                        <div className="text-1e">
                                          {value?.weight?.toFixed(2)}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="text-muted mb-1">
                                          Measurements (inches)
                                        </div>
                                        <div className="text-1e">
                                          <span className="pe-2">
                                            C :{" "}
                                            {value?.chest
                                              ? value?.chest?.toFixed(2)
                                              : "-"}
                                          </span>
                                          <span className="pe-2">
                                            W :{" "}
                                            {value?.waist
                                              ? value?.waist?.toFixed(2)
                                              : "-"}
                                          </span>
                                          <br />
                                          <span className="pe-2">
                                            B :{" "}
                                            {value?.bicep
                                              ? value?.bicep?.toFixed(2)
                                              : "-"}
                                          </span>
                                          <span>
                                            T :{" "}
                                            {value?.thigh
                                              ? value?.thigh?.toFixed(2)
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="text-muted mb-1">
                                          How did you feel?
                                        </div>
                                        <div className="text-1e">
                                          {capitalizeFirstLetter(
                                            value?.feeling,
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {userStats?.length === 0 && !loadingStats && (
                                <h4
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}>
                                  No Data
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <EditCoach
            modalIsOpen={editCoach}
            setIsOpen={setEditCoach}
            closeModal={() => setEditCoach(false)}
            member={userInfo}
            getUserInfo={getUserInfo}
          />
          <EditNotes
            modalIsOpen={editNotes}
            setIsOpen={setEditNotes}
            closeModal={() => setEditNotes(false)}
            selectData={selectData}
            member={userInfo}
            getUserNotes={getUserNotes}
          />
          <EditDate
            modalIsOpen={editDate}
            setIsOpen={setEditDate}
            closeModal={() => setEditDate(false)}
            member={userInfo}
            dateType={dateType}
            key={dateType}
            getUserInfo={getUserInfo}
          />
          <EditDetails
            modalIsOpen={editDetails}
            setIsOpen={setEditDetails}
            closeModal={() => {
              setDetailsType("");
              setEditDetails(false);
            }}
            member={userInfo}
            detailType={detailType}
            key={detailType}
            getUserInfo={getUserInfo}
          />
          <ConfirmationModal
            modalIsOpen={showConfirm}
            setIsOpen={setShowCofirm}
            onClickHandler={() =>
              btnClicked === 1
                ? updateData(0)
                : btnClicked === 2
                ? updateData(1)
                : btnClicked === 7
                ? deleteNotes()
                : dateChangeHanlder()
            }
            isLoading={loading}
            btnClicked={btnClicked}
            closeModal={() => {
              setShowCofirm(false);
              setBtnClicked(0);
            }}
            key={btnClicked}
          />
          <SendEmailModal
            modalIsOpen={sendEmail}
            setIsOpen={setSendEmail}
            closeModal={() => setSendEmail(false)}
            key={sendEmail}
            email={userInfo?.email}
          />
        </div>
      )}
    </>
  );
};

export default MemberProfile;
