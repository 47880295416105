import React, { useState, useEffect, useMemo } from "react";

export const usePagination = (totalCount = 0, limit = 15) => {
  const [current, setCurrent] = useState(1);
  const [links, setLinks] = useState([]);

  const totalPages = useMemo(
    () =>
      totalCount < limit
        ? 1
        : totalCount % limit === 0
        ? totalCount / limit
        : Math.floor(totalCount / limit) + 1,
    [totalCount, limit]
  );

  useEffect(() => {
    if (totalPages < 6) {
      setLinks([
        ...Array(totalPages)
          .fill()
          .map((_, i) => i + 1),
      ]);
    } else {
      setLinks([1, 2, "...", totalPages - 1, totalPages]);
    }
  }, [totalPages]);

  const onFirstClick = () => {
    setCurrent(1);
    if (totalPages > 5) setLinks([1, 2, 3, "...", totalPages - 1, totalPages]);
  };

  const onLastClick = () => {
    setCurrent(totalPages);
    if (totalPages > 5) setLinks([1, 2, 3, "...", totalPages - 1, totalPages]);
  };

  const goToPage = (page) => {
    setCurrent(page);
    if (totalPages > 5) {
      if (page < totalPages - 2 && page > 3) {
        setLinks([1, "...", page - 1, page, page + 1, "...", totalPages]);
      }
      if (page === 3) {
        setLinks([1, 2, page, 4, "...", totalPages]);
      }
      if (page < 3 || page > totalPages - 2) {
        setLinks([1, 2, 3, "...", totalPages - 2, totalPages - 1, totalPages]);
      }
      if (page === totalPages - 2) {
        setLinks([1, "...", page - 1, page, totalPages - 1, totalPages]);
      }
    }
  };

  const onNextClick = () => {
    let page = current + 1;
    setCurrent(page);
    if (totalPages > 5) {
      if (page < totalPages - 2 && page > 3) {
        setLinks([1, "...", page - 1, page, page + 1, "...", totalPages]);
      }
      if (page === 3) {
        setLinks([1, 2, page, 4, "...", totalPages]);
      }
      if (page < 3 || page > totalPages - 2) {
        setLinks([1, 2, 3, "...", totalPages - 2, totalPages - 1, totalPages]);
      }
      if (page === totalPages - 2) {
        setLinks([1, "...", page - 1, page, totalPages - 1, totalPages]);
      }
    }
  };

  const onPrevClick = () => {
    let page = current - 1;
    setCurrent(page);
    if (totalPages > 5) {
      if (page < totalPages - 2 && page > 3) {
        setLinks([1, "...", page - 1, page, page + 1, "...", totalPages]);
      }
      if (page === 3) {
        setLinks([1, 2, page, 4, "...", totalPages]);
      }
      if (page < 3 || page > totalPages - 2) {
        setLinks([1, 2, 3, "...", totalPages - 2, totalPages - 1, totalPages]);
      }
      if (page === totalPages - 2) {
        setLinks([1, "...", page - 1, page, totalPages - 1, totalPages]);
      }
    }
  };

  return {
    totalCount,
    totalPages,
    current,
    limit,
    links,
    onFirstClick,
    onLastClick,
    onNextClick,
    onPrevClick,
    goToPage,
  };
};
