import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FourOFour from "./views/404";
import Login from "./views/auth/Login";
import Dashboard from "./views/dashboard";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/login"
          render={({ history }) => <Login history={history} />}
          exact
        />

        <Route
          path="/"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/global-stats"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/dashboard"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/coaches"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/members"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/weekly-photos"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/daily-stats"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/testimonials"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/referral"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/profile"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/recipes"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/kudos-board"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/bonusVideos"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/journey"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/restartjourney"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/photo-stamps"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />

        <Route render={({ history }) => <FourOFour history={history} />} />
      </Switch>
    </Router>
  );
};

export default App;
