import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminMembers,
  getCoachMembers,
  saveUserPlaneStartsOn,
} from "../store/actions/dashboardAction";
import { getLocalData } from "../utils/functions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: 400,
  },

  overlay: {
    zIndex: 100000,
  },
};

const EditCoach = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  member,
  getUserInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState("form");

  const [coachId, setCoachId] = useState(null);

  const { coachMebers } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();

  const userType = getLocalData("userType");

  const saveData = async () => {
    const payload = {
      id: member?.id,
      coachId: +coachId,
    };
    setIsLoading(true);
    await dispatch(saveUserPlaneStartsOn(payload));
    await getUserInfo(member, true);
    setPage("success");
    setIsLoading(false);

    if (userType === "admin") {
      dispatch(getAdminMembers());
    } else {
      dispatch(getCoachMembers());
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold"></h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 20,
          }}>
          {page === "form" ? (
            <>
              <h5
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: 10,
                }}>
                Select Coach
              </h5>
              <div className="form-group col-10 col-md-10 me-2 me-xl-3">
                <select
                  className="form-select form-control form-control-sm border bg-white"
                  onChange={(e) => setCoachId(e.target.value)}
                  style={{ color: "#333" }}>
                  <option
                    selected
                    disabled>
                    Select
                  </option>
                  <option
                    selected={!member?.coach}
                    value={0}>
                    No Coach
                  </option>
                  {coachMebers
                    ?.filter((val) => val?.isCoach)
                    ?.map((val) => (
                      <option
                        selected={member?.coach?.id == val?.id}
                        value={val?.id}
                        key={val?.id}>
                        {val?.name}
                      </option>
                    ))}
                </select>
              </div>
              <button
                disabled={isLoading || !coachId}
                className="btn w-50 btn-lg br-10 mt-4"
                onClick={saveData}>
                {isLoading ? "Saving" : "Save"}
              </button>
            </>
          ) : (
            <>
              <h5
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: 10,
                }}>
                Coach assigned successfully!
              </h5>

              <button
                className="btn w-50 btn-lg br-10 mt-4"
                onClick={() => {
                  closeModal();
                  setPage("form");
                }}>
                Done
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditCoach;
