import axios from "axios";

const getHeader = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

const getFormDataHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

export default class ApiService {
  static post(url, data, header = {}) {
    return axios.post(url, JSON.stringify(data), {
      headers: { ...getHeader(), ...header },
    });
  }

  static postFormData(url, data, header = {}) {
    return axios.post(url, data, {
      headers: { ...getFormDataHeader(), ...header },
    });
  }

  static get(url, header = {}) {
    return axios.get(url, {
      headers: { ...getHeader(), ...header },
    });
  }
}
