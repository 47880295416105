import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addResetDataAction,
  saveUserPlaneStartsOn,
} from "../../store/actions/dashboardAction";
import FormTextField from "../../forms/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getLocalData } from "../../utils/functions";
import DateInput from "../../forms/DateInput";
import HighFatDate from "../../modals/high-fat-date";
import { getUserProfile } from "../../store/actions/authAction";
import ConfirmationModal from "../../modals/confirmation-modal";

const schema = yup.object({
  phone: yup.string().trim().required("Cannot be empty"),
  // high_fat: yup.string().trim().required("Cannot be empty"),
  age: yup.string().trim().required("Cannot be empty"),
  height: yup.string().trim().required("Cannot be empty"),
  current_weight: yup.string().trim().required("Cannot be empty"),
  goal_weight: yup.string().trim().required("Cannot be empty"),
  policy: yup.string().trim(),
  medical_issues: yup.string().trim(),
  medications: yup.string().trim(),
});

const RestartJourney = ({ loginUser, history }) => {
  const dispatch = useDispatch();
  const [accountability, setAccountability] = useState("");
  const [highFatDate, setHighFatDate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState("form");
  const [policy, setPolicy] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [showConfirm, setShowCofirm] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (getLocalData("high_fat_date"))
      setDate(moment(getLocalData("high_fat_date"))?.format("MM/DD/YYYY"));
  }, [getLocalData("high_fat_date")]);

  const saveData = async (data) => {
    const payload = {
      ...data,
      high_fat: new Date(date).getTime(),
      accountability,
    };

    setIsLoading(true);
    const res = await dispatch(addResetDataAction(payload));
    dispatch(
      saveUserPlaneStartsOn({
        id: loginUser?.id,
        coachId: 0,
      }),
    );
    dispatch(
      saveUserPlaneStartsOn({
        id: loginUser?.id,
        completed: "0",
      }),
    );
    if (res.status === "success") {
      handleResetData();
    }
    setIsLoading(false);
  };

  const handleResetData = async () => {
    const payloadData = {
      id: loginUser?.id,
      planStartedOn: new Date().getTime(),
    };
    await dispatch(saveUserPlaneStartsOn(payloadData));
    await dispatch(getUserProfile());
    setShowCofirm(true);
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div
          className="card-body"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}>
          <div className="col-md-8 mt-4">
            <h4
              className="mb-2"
              style={{ textAlign: "center" }}>
              Restart My Weight Loss Journey
            </h4>
            <form
              className="d-flex flex-column justify-content-between h-100 mt-4"
              onSubmit={handleSubmit(saveData)}>
              <div className="row">
                <div className="col-md-6">
                  <FormTextField
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label="Phone"
                    fieldName="phone"
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6 mt-3">
                  <label className="col-12 fb-label">
                    What will be your first high fat date?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <DateInput
                    date={date}
                    setDate={setDate}
                    highFatClick={() => setHighFatDate(true)}
                    highFatClickLocal={true}
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{ marginTop: 15 }}>
                  <FormTextField
                    labelClassName="col-12 fb-label"
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label="Age"
                    fieldName="age"
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <FormTextField
                    labelClassName="col-12 fb-label"
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label="Height (inches)"
                    fieldName="height"
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <FormTextField
                    labelClassName="col-12 fb-label"
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label="Current Weight"
                    fieldName="current_weight"
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <FormTextField
                    labelClassName="col-12 fb-label"
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label="Goal Weight"
                    fieldName="goal_weight"
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <FormTextField
                    labelClassName="col-12 fb-label"
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label="Medical issues and/or surgeries"
                    fieldName="medical_issues"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="col-12 mt-3">
                  <FormTextField
                    labelClassName="col-12 fb-label"
                    divClassName="col-md-12"
                    innerDivClassName="col-md-12"
                    label='Please list any of your current medications, vitamins, supplements, or type "None"'
                    fieldName="medications"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div
                  className="form-check"
                  style={{ paddingLeft: 40, marginTop: 10 }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    onChange={(e) => {
                      setPolicy(e.target.checked);
                      setValue("policy", e.target.checked);
                    }}
                  />

                  <label
                    style={{ marginTop: 5, marginLeft: 10 }}
                    className="form-check-label"
                    for="flexCheckChecked">
                    I have read and agree with the return policy.{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>

                <p
                  className="col-md-12"
                  style={{
                    width: "95%",
                    padding: 10,
                    marginTop: 20,
                    marginLeft: "2%",
                    border: "1px solid #333",
                  }}>
                  There are NO refunds or returns for any weight loss drops,
                  weight loss packages, or supplements that have been opened.
                  There are no returns on any of our other products. You may
                  return UNOPENED Dual Management Weight Loss Drops or New
                  Client Packages as sent to you within 10 days of the delivery
                  date for a partial refund. A return fee of $55 will be
                  deducted. Here are the details on how to ship your products
                  back: ALL material must be unused, unopened, and returned in
                  full using USPS priority mail or an equivalant shipper that
                  provides a tracking number. Refunds will be dispersed back to
                  the method of payment at the time of original purchase, upon
                  receipt of the merchandise. It is advised that you communicate
                  with our staff that the package is coming back by calling the
                  office (845-713-4320) or emailing us at
                  info@breakthroughm2.com
                </p>

                <div
                  className="row mt-4 mb-4"
                  style={{ paddingRight: 0 }}>
                  <div className="col-md-6 col-sm-6">
                    <button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push("/dashboard");
                      }}
                      className="btn w-100 wizard-next-btn grey-btn br-10"
                      style={{ background: "#ccc", color: "#333" }}>
                      Close
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-6 margin-top-mobile">
                    <button
                      className="btn w-100 br-10"
                      type="submit"
                      disabled={isLoading || !policy}>
                      {isLoading ? "Processing" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <HighFatDate
          modalIsOpen={highFatDate}
          setIsOpen={setHighFatDate}
          closeModal={() => setHighFatDate(false)}
        />
        <ConfirmationModal
          modalIsOpen={showConfirm}
          setIsOpen={setShowCofirm}
          onClickHandler={handleResetData}
          isLoading={isLoading}
          btnClicked={5}
          closeModal={() => {
            history.push("/dashboard");
            window.scrollTo(0, 0);
            window.location.reload();
          }}
          key={5}
        />
      </div>
    </div>
  );
};

export default RestartJourney;
