import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  getComments,
  getPosts,
  savePosts,
} from "../store/actions/dashboardAction";
import moment from "moment";
import { setDashboardData } from "../store/actions/dashboardAction";

const AddCommentUI = ({
  closeModal,
  payload,
  selectedPost,
  loginUser,
  setPosts,
  setAllPosts,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);

  const addCommentHandler = async (typeId) => {
    let allComments = [...comments];
    allComments.unshift({
      userPic: "",
      commentTypeId: typeId,
      time: Date.now(),
      userName: loginUser.name,
    });
    setComments(allComments);

    const data = {
      postId: selectedPost?.id,
      typeId,
    };
    const res = await dispatch(addComment(data));

    if (res.status === "success") {
      dispatch(getComments(selectedPost?.id)).then((data) => {
        setComments(data);
      });

      dispatch(getPosts(payload)).then((data) => {
        setPosts(data?.slice(0, currentPage * 5));
        setAllPosts(data);
      });
    }
  };

  useEffect(() => {
    dispatch(getComments(selectedPost?.id)).then((data) => {
      setComments(data);
    });
  }, [selectedPost]);

  return (
    <div
      className="modal-content modal-content-add-comment"
      style={{ overflowY: "auto", overflowX: "hidden" }}>
      <div className="modal-header border-bottom col-md-12">
        <div className="posts-block border-0 p-0 col-md-12">
          <div className="row align-items-md-center">
            <div className="d-flex align-items-center col-md-3 mb-sm-0 mb-2">
              {selectedPost?.userPic ? (
                <img
                  src={selectedPost?.userPic}
                  alt="user"
                  className="me-2 rounded-pill"
                  height="45"
                  width="45"
                />
              ) : (
                <a
                  className={`nav-link dropdown-toggle hide-arrow btn-circle user-default-pic`}
                  role="button"
                  data-bs-toggle="dropdown">
                  <i className="bx bxs-user"></i>
                </a>
              )}
              <div className="">
                <h6 className="mb-0">{selectedPost.userName}</h6>
                <div className="text-muted">
                  {moment(new Date(selectedPost?.time)).fromNow()}
                </div>
              </div>
            </div>
            <div className="col-md-8 ps-md-0">
              <h5 className="mb-1 px-2">{selectedPost?.text}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-body comments-block">
        {comments?.length == 0 && <div>No Comments Yet</div>}
        {comments?.length > 0 && (
          <>
            {comments?.map((comment) => (
              <div className="posts-block" style={{ padding: 0 }}>
                <div className="row align-items-md-center">
                  <div className="d-flex align-items-center col-md-3 col-5 mb-sm-0 mb-2">
                    {comment?.userPic ? (
                      <img
                        src={comment?.userPic}
                        alt="user"
                        className="me-2 rounded-pill"
                        height="45"
                        width="45"
                      />
                    ) : (
                      <a
                        className={`nav-link dropdown-toggle hide-arrow btn-circle user-default-pic`}
                        role="button"
                        data-bs-toggle="dropdown">
                        <i className="bx bxs-user"></i>
                      </a>
                    )}
                    <div className="">
                      <h6 className="mb-0">{comment?.userName}</h6>
                      <div className="text-muted small">
                        {" "}
                        {moment(new Date(comment?.time)).fromNow()}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-7 ps-md-0">
                    <p className="comment-text">
                      {comment?.commentTypeId === 1
                        ? "Health is Wealth!"
                        : comment?.commentTypeId === 2
                        ? "You look AMAZING!"
                        : comment?.commentTypeId === 3
                        ? "Teamwork makes the dream work!"
                        : comment?.commentTypeId === 3
                        ? "Teamwork makes the dream work!"
                        : comment?.commentTypeId === 4
                        ? "You're inspiring me!"
                        : comment?.commentTypeId === 5
                        ? "I'm so happy for you!"
                        : comment?.commentTypeId === 6
                        ? "I'm feeling motivated. Thank you!"
                        : comment?.commentTypeId === 7
                        ? "We're seeing less of you!"
                        : comment?.commentTypeId === 8
                        ? "Great job! You are breaking through!"
                        : "Your success is so encouraging to me!"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {localStorage.userId && localStorage.userId != selectedPost.userId && (
        <div className="modal-footer border-0">
          <div className="posts-block border-0 p-0 mb-0">
            <div className="row">
              <div className="d-flex col-md-3 mb-sm-0 mb-2">
                {loginUser?.profilePic ? (
                  <img
                    src={loginUser?.profilePic}
                    alt="user"
                    className="me-2 rounded-pill"
                    height="45"
                    width="45"
                  />
                ) : (
                  <a
                    className={`nav-link dropdown-toggle hide-arrow btn-circle user-default-pic`}
                    role="button"
                    data-bs-toggle="dropdown">
                    <i className="bx bxs-user"></i>
                  </a>
                )}
                <div className="">
                  <h6 className="mt-2">{loginUser.name}</h6>
                  {/* <div className="text-muted small"></div> */}
                </div>
              </div>
              <div className="col-md-9 ps-md-0">
                <div className="p-comments">
                  <p>Show your support :</p>
                  <div className="p-comments-block">
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(1)}>
                      Health is Wealth!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(2)}>
                      You look AMAZING!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(4)}>
                      You're inspiring me!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(3)}>
                      Teamwork makes the dream work!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(5)}>
                      I'm so happy for you!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(6)}>
                      I'm feeling motivated. Thank you!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(7)}>
                      We're seeing less of you!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(8)}>
                      Great job! You are breaking through!
                    </p>
                    <p
                      className="comment-text"
                      onClick={() => addCommentHandler(9)}>
                      Your success is so encouraging to me!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCommentUI;
