import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosts,
  getTestimonial,
  savePosts,
  saveTestimonial,
} from "../store/actions/dashboardAction";

const AddTestimonialUI = ({
  closeModal,
  payload,
  loginUser,
  setTestimonials,
  setAllTestimonials,
}) => {
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.ui);

  const addPostHandler = async () => {
    const data = {
      testimonial: message,
    };
    if (!message) {
      setErrorMsg("Cannot be empty");

      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
      return;
    }

    const res = await dispatch(saveTestimonial(data));
    if (res.status === "success") {
      dispatch(getTestimonial()).then((data) => {
        setTestimonials(data?.slice(0, 5));
        setAllTestimonials(data);
        closeModal();
      });
    }
  };

  return (
    <div className="modal-content modal-content-add-post">
      <div className="modal-header border-bottom">
        <div className="posts-block border-0 p-0">
          <div className="row align-items-md-center">
            <div className="d-flex align-items-center col-md-8 mb-sm-0 mb-2">
              <img
                src="assets/img/avatars/default-user3.png"
                alt="user"
                className="me-3 rounded-pill"
                height="50"
              />

              <div className="col-md-12">
                <h5 className="mb-2 bold">{loginUser?.name}</h5>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={closeModal}
          aria-label="Close">
          <i className="bx bx-plus bx-rotate-45"></i>
        </button>
      </div>
      <div className="modal-body">
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className="form-control h-auto bg-white border-0 p-0"
          rows="7"
          placeholder="Share your thoughts.."></textarea>
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginTop: 4,
            position: "absolute",
          }}>
          {errorMsg && !message ? errorMsg : ""}
        </p>
      </div>
      <div className="modal-footer">
        <button
          className="btn w-100 btn-lg br-10"
          onClick={addPostHandler}
          disabled={isLoading}>
          {isLoading ? "Posting" : "Post"}
        </button>
      </div>
    </div>
  );
};
export default AddTestimonialUI;
