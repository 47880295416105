import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getTestimonial } from "../../store/actions/dashboardAction";
import AddTestimonial from "../../modals/add-testimonials";

const Testimonials = ({ loginUser }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [testimonials, setTestimonials] = useState([]);
  const [allTestimonials, setAllTestimonials] = useState([]);
  const [onlyMyPost, setOnlyMyPost] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestimonial(onlyMyPost)).then((data) => {
      setTestimonials(data?.slice(0, 5));
      setAllTestimonials(data);
    });
  }, [onlyMyPost]);

  useEffect(() => {
    if (currentPage > 1)
      setTestimonials(allTestimonials?.slice(0, currentPage * 5));
  }, [currentPage]);

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div
        className="card shadow-none main-card"
        style={{ backgroundColor: "", height: "99%" }}>
        <div className="card-body">
          <div
            className="row justify-content-center post-scroll"
            id="state-scroll"
            style={{ backgroundColor: "", height: "100%" }}>
            <div className="col-md-10 col-xl-9">
              <div className="row align-items-center mb-3 g-md-4 g-2">
                <div className="col-md-8 text-muted d-flex align-items-center">
                  <div className="form-group col-4">
                    <div className="form-check form-switch">
                      <label
                        className="form-check-label ps-2"
                        for="flexSwitchCheckChecked">
                        My Testimonials
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        onChange={(e) =>
                          setOnlyMyPost(onlyMyPost === 0 ? 1 : 0)
                        }
                        checked={onlyMyPost}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-md-end">
                  <a
                    onClick={() => setIsOpen(true)}
                    data-bs-toggle="modal"
                    data-bs-target="#addPostModal"
                    className="btn btn-outline-primary br-10"
                    style={{ height: 35, paddingTop: 7 }}>
                    + Add New
                  </a>
                </div>
              </div>

              {testimonials.length == 0 && (
                <div className="posts-block">No Testimonials</div>
              )}

              {testimonials?.map((post) => (
                <div className="posts-block">
                  <div className="row mb-md-3 mb-2 align-items-md-center">
                    <div
                      className="d-flex align-items-center col-md-3 mb-sm-0 mb-2"
                      style={{ backgroundColor: "" }}>
                      {post?.userPic ? (
                        <img
                          src={post?.userPic}
                          alt="user"
                          className="me-2 rounded-pill"
                          height="45"
                          width="45"
                        />
                      ) : (
                        <a
                          className={`nav-link dropdown-toggle hide-arrow btn-circle user-default-pic`}
                          role="button"
                          data-bs-toggle="dropdown">
                          <i className="bx bxs-user"></i>
                        </a>
                      )}
                      <div className="">
                        <h6 className="mb-0">{post?.userName}</h6>
                        <div className="text-muted">
                          {moment(new Date(post?.time)).fromNow()}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-8 ps-md-0"
                      style={{ backgroundColor: "" }}>
                      <h5 className="mb-1 px-2">{post?.testimonial}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="col-md-12 text-center py-3"
              style={{ color: "#088EAC" }}>
              {currentPage * 5 < allTestimonials?.length ? (
                <a
                  role="button"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  className="semi h6">
                  Load More Posts <i className="bx bx-chevrons-down ms-2"></i>
                </a>
              ) : (
                allTestimonials?.length > 0 &&
                currentPage > 1 && (
                  <p className="semi h6">All Testimonials Loaded</p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <AddTestimonial
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        loginUser={loginUser}
        closeModal={() => setIsOpen(false)}
        setTestimonials={setTestimonials}
        setAllTestimonials={setAllTestimonials}
      />
    </div>
  );
};

export default Testimonials;
