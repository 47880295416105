import React from "react";

const PhotoStamps = () => {
  const links = [
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-0_Prep-Week.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-1_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-2_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-3_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-4_step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-5_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-6_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-7_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-8_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-9_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-10_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-11_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-12_Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-___Step-2.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Step-2_Fat-Loss.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-1_Step-4.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-2_Step-4.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-3_Step-4.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-4_Step-4-pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-5_Step-4.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Week-6_Step-4.pdf",
    "https://beta.alulawellness.com/wp-content/uploads/2023/12/Im-Finished.pdf",
  ];
  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div
          className="card-body"
          style={{ minHeight: "100vh" }}>
          <h5
            style={{
              marginBottom: 0,
              fontWeight: 900,
              fontSize: 18,
              color: "#566a7f",
            }}>
            Photo Stamps
          </h5>
          <div style={{ paddingTop: 20 }}>
            {links?.map((link) => (
              <div className="posts-block">
                <a
                  style={{ display: "block", paddingTop: 5 }}
                  target="_blank"
                  href={link}>
                  {link}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoStamps;
