import React from "react";
import FormTextField from "../../forms/FormTextField";
import { getLocalData } from "../../utils/functions";

const Profile = ({ loginUser }) => {
  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div
          className="card-body"
          style={{ minHeight: "100vh" }}>
          <div className="pt-4 row g-3 justify-content-between">
            <div className="col-lg-3 col-md-4 col-sm-4">
              <div className="col-md-12">
                <img
                  src={loginUser?.profilePic}
                  style={{ height: 170 }}
                />
                <br />
                <br />
                <a
                  style={{ fontWeight: 700, fontSize: 16, paddingTop: 40 }}
                  target="_blank"
                  href={`https://beta.alulawellness.com/my-account/edit-account?mo_jwt_token=${getLocalData(
                    "mo_jwt_token",
                  )}`}
                  className="">
                  Edit Account
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-6">
              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Name :</div>
                <div className="col-md-8">
                  <p className="tag">
                    <span className="tag-val">{loginUser?.name}</span>
                  </p>
                </div>
              </div>

              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Username :</div>
                <div className="col-md-8">
                  <p className="tag">
                    <span className="tag-val">{loginUser?.username}</span>
                  </p>
                </div>
              </div>

              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Email :</div>
                <div className="col-md-8">
                  <p className="tag">
                    <span className="tag-val">{loginUser?.useremail}</span>
                  </p>
                </div>
              </div>

              {loginUser?.completedDays > 0 && (
                <>
                  <div className="col-md-12 row">
                    <div className="col-md-4 tag"> Start weight :</div>
                    <div className="col-md-8">
                      <p className="tag">
                        <span className="tag-val">
                          {loginUser?.startWeight
                            ? `${loginUser?.startWeight} lbs`
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 row">
                    <div className="col-md-4 tag"> Goal weight :</div>
                    <div className="col-md-8">
                      <p className="tag">
                        <span className="tag-val">
                          {loginUser?.goalWeight
                            ? `${loginUser?.goalWeight} lbs`
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
