import { SET_UI_DATA } from "../constants/uiConstants";

const initialState = {
  isLoading: false,
  error: "",
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UI_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
