import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "visible",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: 400,
  },

  overlay: {
    zIndex: 100000,
  },
};

const CoachingLinksModal = ({ modalIsOpen, afterOpenModal, closeModal }) => {
  const { coachingLinks } = useSelector((state) => state.dashboard);
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: -10,
          }}>
          <h4 className="mb-2 bold">Group Coaching Sessions</h4>
          <div className="modal-header">
            <button
              type="button"
              onClick={closeModal}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close">
              <i className="bx bx-plus bx-rotate-45"></i>
            </button>
          </div>
        </div>

        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
          }}>
          {coachingLinks?.map((val, i) => (
            <a
              key={i}
              style={{ fontWeight: 700, fontSize: 16 }}
              target="_blank"
              href={val?.url}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M3 3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.445.993.993v16.014a.994.994 0 0 1-.993.993H3.993A.993.993 0 0 1 3 20.007V3.993Zm7.622 4.422a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.401.401 0 0 0 0-.666l-4.88-3.252Z"
                />
              </svg>{" "}
              {val?.text}
            </a>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default CoachingLinksModal;
