import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosts,
  saveNotification,
  savePosts,
  updateNotificationStatus,
} from "../store/actions/dashboardAction";
import { getLocalData } from "../utils/functions";

const AddPostsUI = ({
  closeModal,
  payload,
  loginUser,
  setPosts,
  setAllPosts,
}) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.ui);

  const addPostHandler = async () => {
    const data = {
      text: message,
      category: +category,
      isPrivate,
    };
    if (!message) {
      setErrorMsg("Cannot be empty");

      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
      return;
    }

    const res = await dispatch(savePosts(data));
    if (res.status === "success") {
      dispatch(updateNotificationStatus({ type: "unread" }));

      const payloadNotification = {
        userId: 0,
        postId: res?.id,
        notification: `${loginUser?.name} added a new post!`,
      };

      dispatch(saveNotification(payloadNotification));

      dispatch(getPosts(payload)).then((data) => {
        setPosts(data?.slice(0, 5));
        setAllPosts(data);
        closeModal();
      });
    }
  };

  return (
    <div className="modal-content modal-content-add-post">
      <div
        className="modal-header"
        style={{ zIndex: 2 }}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={closeModal}
          aria-label="Close">
          <i className="bx bx-plus bx-rotate-45"></i>
        </button>
      </div>

      <div
        className="modal-body"
        style={{ marginTop: -20, zIndex: 1 }}>
        <div
          className="border-bottom"
          style={{ marginBottom: 20 }}>
          <div className="posts-block border-0 p-0">
            <div className="row align-items-md-center">
              <div className="d-flex align-items-center col-md-6 mb-sm-0 mb-2">
                <img
                  src={getLocalData("profilePic")}
                  alt="user"
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                  }}
                  className="me-3 rounded-pill"
                  height="50"
                />

                <div className="col-md-12">
                  <h5 className="mb-2 bold">{loginUser?.name}</h5>

                  <div className="pp-type d-flex align-items-center">
                    <div
                      className="form-check me-3"
                      onClick={() => setIsPrivate(false)}>
                      <input
                        className="form-check-input"
                        name="pp-type"
                        type="radio"
                        value=""
                        id="pb"
                        checked={!isPrivate}
                      />
                      <label
                        className="form-check-label semi"
                        for="pb">
                        <i className="bx bxs-lock-open me-2"></i>Public
                      </label>
                    </div>
                    <div
                      className="form-check"
                      onClick={() => setIsPrivate(true)}>
                      <input
                        className="form-check-input"
                        name="pp-type"
                        type="radio"
                        value=""
                        id="pv"
                        checked={isPrivate}
                      />
                      <label
                        className="form-check-label semi"
                        for="pv">
                        <i className="bx bxs-lock me-2"></i>Private
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-md-4">
                <label className="text-muted pb-1">Post Type</label>
                <select
                  className="form-select form-control form-control-sm border bg-white"
                  onChange={(e) => setCategory(e.target.value)}
                  style={{ color: "#333" }}>
                  <option value="1">Milestone</option>
                  <option value="2">Kitchen Tip</option>
                  <option value="3">Food/Recipe Tip</option>
                  <option value="4">Nonscale Victory</option>
                  <option value="5">Aha Moment</option>
                </select>
              </div>

              <span
                className="col-md-7 mt-2 post-desc"
                style={{ fontSize: 12, color: "#8f97a9" }}>
                {isPrivate
                  ? "A Private post means it is for your eyes only."
                  : "A Pubic post will be visible BTM2 Dashboard users on this platform (not social media platforms like Team BTM2)."}
              </span>
            </div>
          </div>
        </div>

        <textarea
          style={{ overflow: "hidden" }}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className="form-control h-auto bg-white border-0 p-0"
          rows="7"
          placeholder="Share your thoughts.."></textarea>
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginTop: 4,
            position: "absolute",
          }}>
          {errorMsg && !message ? errorMsg : ""}
        </p>
      </div>
      <div className="modal-footer">
        <button
          className="btn w-100 btn-lg br-10"
          onClick={addPostHandler}
          disabled={isLoading}>
          {isLoading ? "Posting" : "Post"}
        </button>
      </div>
    </div>
  );
};

export default AddPostsUI;
