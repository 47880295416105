import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { getLocalData } from "../utils/functions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: 180,
    overflow: "hidden",
    width: "380px",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
  },
  overlay: {
    zIndex: 100000,
  },
};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: 220,
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
  },
  overlay: {
    zIndex: 100000,
  },
};

const ConfirmationModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  btnClicked,
  onClickHandler,
  isLoading,
}) => {
  const [view, setView] = useState(btnClicked === 5 ? "success" : "confirm");

  const userType = getLocalData("userType");

  const onClickAction = async () => {
    await onClickHandler();
    if (userType === "admin" && btnClicked != 7) {
      setView("success");
    } else {
      closeModal();
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={btnClicked === 5 ? customStyles2 : customStyles}
        height={400}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div
          className={`authentication-wrapper ${
            btnClicked === 5 ? "confirmation-modal" : ""
          } `}>
          <div className="form-wizard">
            {view === "success" ? (
              <h6
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                  lineHeight: 1.2,
                }}>
                {btnClicked === 1 ? (
                  "Data saved"
                ) : btnClicked === 2 ? (
                  "Data saved"
                ) : btnClicked === 4 ? (
                  "Data saved"
                ) : btnClicked === 5 ? (
                  <span>
                    Your program has been restarted. <br /> Brush up by
                    reviewing the Breakthrough Basics and Steps 1 & 2 video
                    series. <br /> Re-read your detailed manual on your
                    dashboard.
                  </span>
                ) : btnClicked == 3 ? (
                  "Start date removed"
                ) : btnClicked == 31 ? (
                  "Low Calorie date removed"
                ) : btnClicked == 32 ? (
                  "Transition date removed"
                ) : (
                  ""
                )}
              </h6>
            ) : (
              <h5
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                }}>
                {btnClicked === 1 ? (
                  "Go back to step 2?"
                ) : btnClicked === 2 ? (
                  "Begin transition to Step 3?"
                ) : btnClicked === 4 ? (
                  "Confirm Program Exit"
                ) : btnClicked === 5 ? (
                  <span style={{ lineHeight: 1.5 }}>
                    Are you sure you want to reset? <br /> Please note this
                    action cannot be undone. <br />
                  </span>
                ) : btnClicked === 6 ? (
                  "Are you sure you want to delete this entry?"
                ) : btnClicked === 7 ? (
                  "Are you sure you want to delete this entry?"
                ) : btnClicked == 3 ? (
                  "Remove start date?"
                ) : btnClicked == 31 ? (
                  "Remove Low Calorie date?"
                ) : btnClicked == 32 ? (
                  "Remove Transition date?"
                ) : (
                  ""
                )}
              </h5>
            )}
            {view === "confirm" ? (
              <div
                className="row"
                style={{
                  width: "350px",
                  marginTop: btnClicked === 5 ? "-50px" : "-30px",
                }}>
                <div style={{ width: "50%" }}>
                  <button
                    onClick={closeModal}
                    disabled={isLoading}
                    style={{
                      background: btnClicked === 5 && "#ccc",
                      color: btnClicked === 5 && "#333",
                    }}
                    className="btn w-100 mt-5 wizard-next-btn grey-btn">
                    Cancel
                  </button>
                </div>
                <div style={{ width: "50%" }}>
                  <button
                    onClick={onClickAction}
                    disabled={isLoading}
                    style={{
                      background:
                        "linear-gradient(91.11deg, #d95b4c 1.62%, #ee7567 99.05%)",
                    }}
                    className="btn w-100 mt-5 wizard-next-btn">
                    {isLoading
                      ? "Processing"
                      : btnClicked === 5
                      ? "Yes, Reset"
                      : "Confirm"}
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <div
                  style={{
                    width: "350px",
                    marginTop: "-30px",
                  }}>
                  <button
                    onClick={closeModal}
                    disabled={isLoading}
                    className="btn w-100 mt-5 wizard-next-btn">
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
