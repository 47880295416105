import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: "70vw",
    height: "80vh",
  },

  overlay: {
    zIndex: 100000,
  },
};

const CoachCalendly = ({ modalIsOpen, afterOpenModal, closeModal }) => {
  const { coachDetails } = useSelector((state) => state.dashboard);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold"></h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>

        <iframe
          width="100%"
          height="100%"
          class="iframe-placeholder"
          src={coachDetails?.calendly_url || "https://calendly.com/breakthroughm2/15min"}
        />
      </Modal>
    </div>
  );
};

export default CoachCalendly;
