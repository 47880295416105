import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminMembers,
  getCoachMembers,
  saveUserPlaneStartsOn,
  updateCoachNotesAction,
} from "../store/actions/dashboardAction";
import { getLocalData } from "../utils/functions";
import FormDatePicker from "../forms/FormDatePicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextField from "../forms/FormTextField";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "visible",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: 400,
  },

  overlay: {
    zIndex: 100000,
  },
};

const updateSchema = yup.object({
  coachNotes: yup.string().trim().required("Cannot be empty"),
});

const EditNotes = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  selectData,
  getUserNotes,
  member,
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    setValue,
    reset: resetUpdate,
    formState: { errors: errorsUpdate },
  } = useForm({
    resolver: yupResolver(updateSchema),
  });

  useEffect(() => {
    if (selectData?.note) {
      setValue("coachNotes", selectData?.note);
    }
  }, [selectData?.note]);

  const addData = async (data) => {
    const payload = {
      id: selectData?.id,
      coachNotes: data?.coachNotes,
      useremail: member?.useremail,
    };

    setLoading(true);
    await dispatch(updateCoachNotesAction(payload));
    setLoading(false);
    getUserNotes();
    closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold"></h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={() => {
              closeModal();
            }}>
            X
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <form
            className="d-flex flex-column h-100 col-md-12"
            onSubmit={handleSubmitUpdate(addData)}>
            <div className="row align-items-center">
              <FormTextField
                divClassName="col-md-12"
                innerDivClassName="col-sm-12"
                labelClassName="col-12 fb-label pb-2"
                label="Update Notes"
                fieldName="coachNotes"
                register={registerUpdate}
                errors={errorsUpdate}
              />
              <div
                className="col-md-12 mb-2 mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn br-10 w-50">
                  {loading ? "Saving" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditNotes;
