import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: 470,
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: "60vw",
  },
  overlay: {
    zIndex: 100000,
  },
};

const ExitModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  setBtnClicked,
  setShowCofirm,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div
          className="text-center"
          style={{ padding: 10, paddingBottom: 30 }}>
          <h4 className="card-header pb-3 fw-semibold">
            You've decided to end low-cal, now you have two choices.
          </h4>
          <div>
            <p
              className="tag-val"
              style={{ fontSize: 20, marginTop: 10, marginBottom: -5 }}>
              EXIT
            </p>
            <p
              style={{ fontSize: 16, textAlign: "left", lineHeight: 1.6 }}
              className="card-body text-body">
              This is the official way to properly exit our program when you
              have either reached your goal or wanting a significant break from
              diet world. <br />
              Please see pages 48-56 in your manual for more details.
              <br />
              There is a Step 4 Transition class every Saturday in the NY office
              from 12:30-1:30, all are welcome. If that is not possible, click
              here for the Step 4 video. <br />
              We have a separate private FB page for Step 4 transition and there
              is a Transition coach for people in this step. <br />
            </p>

            <div className="row d-flex justify-content-between">
              <div className="col-md-4">
                <button
                  style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    setBtnClicked(4);
                    setShowCofirm(true);
                    closeModal();
                  }}
                  className="btn-admin btn-outline-danger">
                  CONFIRM EXIT
                </button>
              </div>
              <div className="col-md-4">
                <button
                  style={{ width: "90%" }}
                  type="button"
                  onClick={closeModal}
                  className="btn-admin btn-outline-info">
                  CANCEL
                </button>
              </div>
              <h6 className="pb-3 fw-semibold mt-4">
                <a
                  role="button"
                  onClick={() => {
                    setBtnClicked(1);
                    setShowCofirm(true);
                    closeModal();
                  }}
                  style={{ color: "#088eac" }}>
                  Click here{" "}
                </a>
                to cancel transition and go back to step 2.
              </h6>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExitModal;
