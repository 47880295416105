import { getLocalData } from "../../utils/functions";
import { SET_USER_DATA } from "../constants/authConstants";

const initialState = {
  token: getLocalData("token") || null,
  loginUser: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
