import React, { useEffect } from "react";

const CheatedModalUI = ({ setView, view, closeModal, openCoachCalendly }) => {
  useEffect(() => {
    setView("home");
  }, []);

  return (
    <div className="cheated-modal">
      {view === "home" && (
        <>
          <p className="cheated-modal-content">
            <i className="bx bx-error error-icon" /> You have a choice, and a{" "}
            <br /> responsibility to admit that the same <br />
            mind that got you into "unhealthy" is <br /> not going to be the
            same mind that <br /> gets you to "healthy." <br />
          </p>{" "}
          <span>WHERE IS YOUR HEAD AT NOW?</span>
          <div className="cheated-modal-box">
            <div onClick={() => setView("happy")}>
              <i className="bx bxs-smile"></i>
              <p>I did it, I'm past it, I'm moving forward but...</p>
            </div>
            <div onClick={() => setView("sad")}>
              <i className="bx bxs-sad"></i>
              <p>I slashed one tire, might as well slash all four!</p>
            </div>
          </div>
        </>
      )}
      {view === "happy" && (
        <>
          <h6>Moving Forward</h6>
          <p className="cheated-modal-content-smile">
            When people cheat, their negative beliefs have come to the surface.
            This is the time to analyze and evaluate.
          </p>
          <p className="cheated-modal-content-smile">
            Please answer these questions. When you are done, if you want
            someone to hash out your answers with, please contact your coach
            here.
          </p>
          <p className="cheated-modal-content-smile">
            1. Is it worth seeing a gain on the scale? <br /> 2. Is it worth
            waiting 5-7 days for your gody to recover? <br /> 3. Is it worth the
            feelings of guilt? <br /> 4. Is it worth feeling upset to your
            stomach from the foods you cheated with? <br /> 5. Is it worth more
            than your goal? <br /> 6. Is it worth more than you? Breathe, let it
            go, you got this!{" "}
            <a
              role="button"
              onClick={openCoachCalendly}>
              Click here
            </a>{" "}
            to contact your coach.
          </p>
        </>
      )}
      {view === "sad" && (
        <>
          <h6>Ask Yourself Why</h6>
          <p className="cheated-modal-content-smile">
            You are on a self-sabotage, self-destructive path. You need to ask
            yourself why?
          </p>
          <p className="cheated-modal-content-smile">
            Why do you deserve to not reach your goal?
          </p>
          <p className="cheated-modal-content-smile">
            Why do you let outside circumstances rule over your body and your
            mental and emotional well-being?
          </p>
          <p className="cheated-modal-content-smile">
            Why do you punish yourself?
          </p>
          <p className="cheated-modal-content-smile">
            Please answer these questions. When you are done, if you want
            someone to hash out your answers with, please contact your coach
            here.
          </p>
          {/* <p className="cheated-modal-content-smile">
            Please consider our Mentoring Program. Your Inner game needs some
            TLC.
          </p> */}
          <p className="cheated-modal-content-smile">
            <a
              role="button"
              onClick={openCoachCalendly}>
              Click here
            </a>{" "}
            to contact your coach.
          </p>
        </>
      )}
    </div>
  );
};

export default CheatedModalUI;
