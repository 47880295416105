import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../store/actions/authAction";
import { saveUserPlaneStartsOn } from "../store/actions/dashboardAction";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: 470,
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: "60vw",
  },
  overlay: {
    zIndex: 100000,
  },
};

const ReturnModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  loginUser,
  setBtnClicked,
  setShowCofirm,
}) => {
  const [days, setDays] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loginUser?.step4Days) setDays(loginUser?.step4Days);
  }, [loginUser?.step4Days]);

  const dispatch = useDispatch();

  const saveHandler = async () => {
    if (/^[1-9]\d*$/.test(days)) {
      const payload = {
        step4_days: +days,
      };
      setLoading(true);
      const res = await dispatch(saveUserPlaneStartsOn(payload));
      setLoading(false);
      if (res.status === "success") {
        await dispatch(getUserProfile());
        closeModal();
      }
    } else {
      setError("Invalid Value");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div
          className="text-center"
          style={{ padding: 10, paddingBottom: 30 }}>
          <h4 className="card-header pb-3 fw-semibold">
            You've decided to end low-cal, now you have two choices.
          </h4>
          <div>
            <p
              className="tag-val"
              style={{ fontSize: 20, marginTop: 10, marginBottom: -5 }}>
              RETURN
            </p>
            <p
              style={{ fontSize: 16, textAlign: "left", lineHeight: 1.6 }}
              className="card-body text-body">
              We call this step "Interim." It was created for people who need to
              come off the program because they are at the 90-day mark and
              physically speaking, they need to give their body and brain a
              break from low fat, low cal BUT want to stay in the diet mindset
              because they want to finish what they started. <br /> Please see
              pages 44-45 in your manual for more details. Minimum is 2 weeks,
              maximum is 3 weeks. <br />
              You will stay with your low-cal coach for this phase, please let
              them know your intentions.
            </p>

            <div
              className="row"
              style={{ paddingLeft: 20 }}>
              <div className="col-md-4 row">
                <input
                  onChange={(e) => setDays(e.target.value)}
                  class="form-control bg-white"
                  value={days}
                  style={{ borderRadius: 0, height: 40, width: "75%" }}
                />
                <span
                  className="col-md-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  Days
                </span>
                <p style={{ color: "red", marginLeft: -20, marginTop: 5 }}>
                  {error ? error : ""}
                </p>
              </div>
              <div className="col-md-4">
                <button
                  style={{ width: "90%" }}
                  type="button"
                  onClick={saveHandler}
                  className="btn-admin btn-outline-danger">
                  SAVE
                </button>
              </div>
              <div className="col-md-4">
                <button
                  style={{ width: "90%" }}
                  type="button"
                  onClick={closeModal}
                  className="btn-admin btn-outline-info">
                  CANCEL
                </button>
              </div>
              <h6 className="pb-3 fw-semibold mt-4">
                <a
                  role="button"
                  onClick={() => {
                    setBtnClicked(1);
                    setShowCofirm(true);
                    closeModal();
                  }}
                  style={{ color: "#088eac" }}>
                  Click here{" "}
                </a>
                to cancel transition and go back to step 2.
              </h6>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReturnModal;
