import React, { useState } from "react";
import Modal from "react-modal";
import AddPostsUI from "./add-posts-ui";
import AddCommentUI from "./add-comment-ui";
import UpdateStatsUI from "./update-stats-ui";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "transparent",
  },
  overlay: {
    zIndex: 100,
    // backgroundColor: "#1E2A42",
  },
};

const UpdateStats = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  selectedData,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Comment">
        <div className="modal-header">
          <button
            style={{ marginTop: 50, marginRight: 20 }}
            type="button"
            onClick={closeModal}
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i className="bx bx-plus bx-rotate-45"></i>
          </button>
        </div>
        <UpdateStatsUI
          closeModal={closeModal}
          selectedData={selectedData}
        />
      </Modal>
    </div>
  );
};

export default UpdateStats;
