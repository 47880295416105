import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminMembers,
  getCoachMembers,
  saveUserPlaneStartsOn,
} from "../store/actions/dashboardAction";
import { getLocalData } from "../utils/functions";
import FormDatePicker from "../forms/FormDatePicker";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "visible",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: 400,
  },

  overlay: {
    zIndex: 100000,
  },
};

const EditDetails = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  member,
  detailType,
  getUserInfo,
}) => {
  const [page, setPage] = useState("form");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(
    detailType === "age"
      ? member?.age
        ? member?.age
        : member?.bm2_age
        ? member?.bm2_age
        : ""
      : detailType === "height"
      ? member?.height
        ? member?.height
        : member?.bm2_height
        ? member?.bm2_height
        : ""
      : detailType === "medical"
      ? member?.medicalIssues
        ? member?.medicalIssues
        : member?.bm2_medical_issues
        ? member?.bm2_medical_issues
        : ""
      : member?.meds
      ? member?.meds
      : member?.bm2_meds
      ? member?.bm2_meds
      : "",
  );

  const dispatch = useDispatch();

  const saveHandler = async () => {
    const payload = { id: member?.id };

    if (detailType === "age") {
      payload["age"] = value;
    }
    if (detailType === "height") {
      payload["height"] = value;
    }
    if (detailType === "medical") {
      payload["medical"] = value;
    }
    if (detailType === "medications") {
      payload["medications"] = value;
    }
    setLoading(true);
    await dispatch(saveUserPlaneStartsOn(payload));
    await getUserInfo(payload);
    setLoading(false);
    closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold"></h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={() => {
              closeModal();
              setPage("form");
            }}>
            X
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          {page === "form" ? (
            <>
              <div className="form-group col-12 col-md-12">
                <div>
                  <label className="tag mb-2">
                    {detailType === "age"
                      ? "Age"
                      : detailType === "height"
                      ? "Height"
                      : detailType === "medical"
                      ? "Medical Issues"
                      : "Current Medications"}
                  </label>

                  <div className="col-md-12">
                    <input
                      placeholder=""
                      type="text"
                      className="form-control"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  disabled={loading || !value}
                  className="btn w-100 btn-lg br-10 mt-4"
                  onClick={saveHandler}>
                  {loading ? "Saving" : "Save"}
                </button>
              </div>
            </>
          ) : (
            <>
              <h5
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: 10,
                }}>
                date changed successfully!
              </h5>

              <button
                className="btn w-50 btn-lg br-10 mt-4"
                onClick={() => {
                  closeModal();
                  setPage("form");
                }}>
                Done
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditDetails;
