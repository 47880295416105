import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecipeComment,
  deleteRecipeComment,
  getRatings,
  getRecipeComments,
  setDashboardData,
} from "../../store/actions/dashboardAction";
import StarRatingComponent from "react-star-rating-component";
import AddRatings from "../../modals/add-ratings";
import FormTextField from "../../forms/FormTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

const updateSchema = yup.object({
  comment: yup.string().trim().required("Cannot be empty"),
});

const RecipeDetails = ({ recipeDetails, setPage }) => {
  const dispatch = useDispatch();
  const [addRating, setAddRating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentLoad, setCommentsLoad] = useState(false);

  const { receipeRatings } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);
  const { loginUser } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateSchema),
  });

  useEffect(() => {
    dispatch(getRatings(recipeDetails?.id));
    getComments();
  }, []);

  const getComments = async () => {
    setCommentsLoad(true);
    const res = await dispatch(getRecipeComments(recipeDetails?.id));

    if (res?.data?.length) {
      setComments(res?.data);
    }
    setCommentsLoad(false);
  };

  const addComment = async ({ comment }) => {
    const payload = {
      post: recipeDetails?.id,
      author: loginUser?.id,
      content: comment,
    };

    setLoading(true);
    const res = await dispatch(addRecipeComment(payload));
    console.log(res);
    if (res?.status === "success") {
      setComments((prev) => [res?.data, ...prev]);
    }

    reset();
    setLoading(false);
  };

  const deleteCommentHandler = (id) => {
    setComments((prev) => [...prev?.filter((comment) => comment?.id !== id)]);
    dispatch(deleteRecipeComment(id));
  };

  return (
    <div className="card shadow-none main-card">
      <div style={{ padding: 20 }}>
        <a
          role="button"
          style={{ color: "#088eac" }}
          onClick={() => setPage("listing")}>
          <i className="bx bx-left-arrow-alt" /> Back To Listing
        </a>
      </div>
      <div
        className="col-md-12"
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 0,
        }}>
        <img
          src={recipeDetails?.image}
          height={250}
        />
      </div>
      <div
        className="col-md-12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <h4 className="card-header pb-3 fw-semibold">{recipeDetails?.title}</h4>

        <StarRatingComponent
          emptyStarColor={"grey"}
          name="rating"
          starColor="#088eac"
          starCount={5}
          value={isLoading ? 0 : receipeRatings?.average_rating}
          editing={false}
        />
        <h6
          className="card-header pb-3 fw-semibold"
          style={{ marginTop: -10 }}>
          Total Ratings:{" "}
          {isLoading ? (
            <div
              className="spinner-border text-secondary"
              style={{ width: "1rem", height: "1rem" }}
              role="status">
              <span className="sr-only"></span>
            </div>
          ) : (
            receipeRatings?.total_ratings
          )}
        </h6>
        {!receipeRatings?.ratings?.find(
          (val) => val?.rating_userid == loginUser?.id,
        ) &&
          !isLoading && (
            <a
              role="button"
              style={{
                color: "#088eac",
                textDecoration: "underline",
                fontSize: 16,
                fontWeight: 600,
                marginTop: -10,
              }}
              onClick={() => setAddRating(true)}>
              Add Rating
            </a>
          )}
      </div>
      <div className="card-body text-body col-md-12">
        <h4 className="card-header fw-semibold">Ingredients</h4>
        <span
          dangerouslySetInnerHTML={{
            __html: recipeDetails?.ingredients
              ?.replaceAll("<a", "<span")
              ?.replaceAll("<a/>", "<span/>"),
          }}></span>
        <br />
        <h4 className="card-header fw-semibold">Recipe</h4>
        <span
          dangerouslySetInnerHTML={{
            __html: recipeDetails?.recipe
              ?.replaceAll("<a", "<span")
              ?.replaceAll("<a/>", "<span/>"),
          }}></span>{" "}
        <h4 className="card-header fw-semibold">Share Recipe</h4>
        <div
          className="d-flex card-body text-body"
          style={{ display: "flex" }}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${recipeDetails?.guid}`}
            target="_blank"
            style={{ fontSize: 26 }}
            className="menu-link">
            <i className="tf-icons bx bxl-facebook"></i>
            <span style={{ fontSize: 16, fontWeight: 600, marginLeft: -3 }}>
            &nbsp;   Share on Facebook
            </span>
          </a>
          <a
            href={`https://www.instagram.com/?url=${recipeDetails?.guid}&title=${recipeDetails?.title}`}
            target="_blank"
            style={{ paddingLeft: 35 }}
            className="fs-12">
            <svg
              className="menu-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
              />
            </svg>
            <span
              style={{
                fontSize: 16,
                fontWeight: 600,
                marginLeft: -5,
                marginTop:20,
                backgroundColor:''
              }}>
              &nbsp;  Share on Instagram
            </span>
          </a>
        </div>
        {recipeDetails?.youtubeVideo && (
          <div className="card-body text-body col-md-6">
            <iframe
              width="100%"
              height="300"
              src={`https://www.youtube.com/embed/${recipeDetails?.youtubeVideo}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen></iframe>
          </div>
        )}
        <h4 className="card-header fw-semibold">Comments</h4>
        <div className="card-body text-body comments-block">
          {commentLoad ? (
            <p style={{ paddingLeft: 10, fontSize: 16, fontWeight: 600 }}>
              Loading Comments....
            </p>
          ) : (
            <>
              {comments?.length === 0 && (
                <p
                  style={{
                    paddingLeft: 2,
                    color: "#333",
                    fontSize: 16,
                    fontWeight: 600,
                  }}>
                  No comments yet
                </p>
              )}

              {comments?.map((comment) => (
                <div
                  className="posts-block"
                  style={{ padding: 0, backgroundColor: "transparent" }}>
                  <div className="row align-items-md-center">
                    <div className="d-flex align-items-center col-sm-6 col-md-3 col-lg-3 mb-sm-0 mb-2">
                      {comment?.author_avatar_urls[24] ? (
                        <img
                          src={comment?.author_avatar_urls[96]}
                          alt="user"
                          className="me-2 rounded-pill"
                          height="45"
                          width="45"
                        />
                      ) : (
                        <a
                          className={`nav-link dropdown-toggle hide-arrow btn-circle user-default-pic`}
                          role="button"
                          data-bs-toggle="dropdown">
                          <i className="bx bxs-user"></i>
                        </a>
                      )}
                      <div className="">
                        <h6 className="mb-0">{comment?.author_name}</h6>
                        <div className="text-muted small">
                          {moment(new Date(comment?.date)).fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-7 col-lg-7 ps-md-0">
                      <p
                        className="comment-text"
                        dangerouslySetInnerHTML={{
                          __html: comment?.content?.rendered,
                        }}></p>{" "}
                    </div>
                    {comment?.author === loginUser?.id && (
                      <div className="col-md-2 col-2 ps-md-0">
                        <a
                          role="button"
                          style={{
                            color: "red",
                          }}
                          onClick={() => deleteCommentHandler(comment?.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="card-body text-body pt-2 row">
          <div className="col-md-3"></div>

          <form
            className="d-flex flex-column h-100 col-md-7"
            onSubmit={handleSubmit(addComment)}>
            <div className="row align-items-center justyfy-content-center">
              <FormTextField
                divClassName="col-md-12"
                innerDivClassName="col-sm-12"
                labelClassName="col-12 fb-label pb-2"
                label=""
                fieldName="comment"
                register={register}
                errors={errors}
              />

              <div
                className="mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn br-10">
                  {loading ? "Adding" : "Add Comment"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <AddRatings
        modalIsOpen={addRating}
        setIsOpen={setAddRating}
        closeModal={() => setAddRating(false)}
        receipeId={recipeDetails?.id}
      />
    </div>
  );
};

export default RecipeDetails;
