import React, { useState } from "react";
import Modal from "react-modal";
import ReferFrindUI from "./refer-friend-ui";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "transparent",
  },
  overlay: {
    zIndex: 100,
    // backgroundColor: "#1E2A42",
  },
};

const ReferFriend = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  payload,
  modalType,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Post">
        <ReferFrindUI
          closeModal={closeModal}
          payload={payload}
          modalType={modalType}
          message={"Request submitted!"}
        />
      </Modal>
    </div>
  );
};

export default ReferFriend;
