import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { compareDates, getLocalData } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminMembers,
  getCoachMembers,
  getSignups,
} from "../../../store/actions/dashboardAction";

const config = {
  colors: {
    primary: "#088EAC",
    secondary: "#55C9E3",
    success: "#71dd37",
    info: "#03c3ec",
    warning: "#ffab00",
    danger: "#D95B4C",
    dark: "#233446",
    black: "#000",
    white: "#fff",
    body: "#f4f5fb",
    headingColor: "#566a7f",
    axisColor: "#6D778E",
    borderColor: "#eceef1",
  },
};

const GlobalState = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const userType = getLocalData("userType");

  const { coachMebers: members, signupsData } = useSelector(
    (state) => state.dashboard,
  );

  const { loginUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (signupsData?.length) {
      setupWeightGraph(
        signupsData?.map((val) => +val.total),
        signupsData?.map((val) => val.date),
      );
    }
  }, [signupsData]);

  useEffect(() => {
    if (userType === "admin") {
      dispatch(getAdminMembers());
    } else {
      dispatch(getCoachMembers());
    }
    getSignupsHandler();
  }, []);

  const getSignupsHandler = async () => {
    setLoading(true);
    await dispatch(getSignups());
    setLoading(false);
  };

  const setupWeightGraph = (val, date) => {
    const weightGraphConfig = {
      series: [
        {
          name: "Members",
          data: val,
        },
      ],
      chart: {
        height: 415,
        parentHeightOffset: 0,
        parentWidthOffset: 0,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        curve: "smooth",
      },
      legend: {
        show: false,
      },
      markers: {
        size: 4,
        // colors: [config.colors.primary, config.colors.danger],
        colors: [config.colors.primary],
        strokeColors: "",
        strokeWidth: 2,
        // discrete: [
        //   {
        //     fillColor: config.colors.white,
        //     seriesIndex: 0,
        //     dataPointIndex: 7,
        //     strokeColor: config.colors.primary,
        //     strokeWidth: 2,
        //     size: 6,
        //     radius: 8,
        //   },
        // ],
        hover: {
          size: 7,
        },
      },
      colors: ["#82b6c2", config.colors.white],
      fill: {
        type: "gradient",
      },
      grid: {
        borderColor: config.colors.borderColor,
        strokeDashArray: 3,
        padding: {
          top: 0,
          bottom: 0,
          left: -0,
          right: 0,
        },
      },

      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      xaxis: {
        categories: date,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            fontSize: "10px",
            colors: config.colors.axisColor,
          },
        },
      },
      yaxis: {
        labels: {
          offsetX: -15,
          offsetY: 0,
          style: {
            fontSize: "10px",
            colors: config.colors.axisColor,
          },
        },
        min: 0,
        max: 1000,
        tickAmount: 7,
      },
    };

    var weightGraphOrigin = document.querySelector("#weight");

    if (weightGraphOrigin) {
      var weightGraph = new ApexCharts(
        document.querySelector("#weight"),
        weightGraphConfig,
      );
      weightGraph.render();
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div className="card-body">
          <div className="col-md-10 text-md-start back-btn-box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20 13.75a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75v6.75H14V4.25c0-.728-.002-1.2-.048-1.546c-.044-.325-.115-.427-.172-.484c-.057-.057-.159-.128-.484-.172C12.949 2.002 12.478 2 11.75 2c-.728 0-1.2.002-1.546.048c-.325.044-.427.115-.484.172c-.057.057-.128.159-.172.484c-.046.347-.048.818-.048 1.546V20.5H8V8.75A.75.75 0 0 0 7.25 8h-3a.75.75 0 0 0-.75.75V20.5H1.75a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5H20v-6.75Z"
              />
            </svg>{" "}
            <span style={{ fontSize: 18, fontWeight: 700, color: "#333" }}>
              Global Stats
            </span>
          </div>
          <div className="row g-3">
            <div className="col-md-12">
              <div className="col-md-12 g-3 mb-3">
                <div className="card text-black h-100">
                  <div
                    className="card-body"
                    style={{ padding: 0 }}>
                    <div className="row g-3 align-items-center">
                      <div
                        className={`col-md-${
                          userType === "admin" ? "2" : "3"
                        } pt-3`}>
                        <div className="stats-box">
                          <p className="stats-number">
                            {
                              members
                                ?.filter((member) =>
                                  userType === "coach"
                                    ? member?.assignedCoachId == loginUser?.id
                                    : member,
                                )
                                ?.filter(
                                  (val) => val?.status_category === "pending",
                                )?.length
                            }
                          </p>
                          <p className="stats-label">Pending</p>
                        </div>
                      </div>
                      <div
                        className={`col-md-${
                          userType === "admin" ? "2" : "3"
                        } pt-3`}>
                        <div className="stats-box">
                          <p className="stats-number">
                            {
                              members
                                ?.filter((member) =>
                                  userType === "coach"
                                    ? member?.assignedCoachId == loginUser?.id
                                    : member,
                                )
                                ?.filter(
                                  (val) => val?.status_category === "active",
                                )?.length
                            }
                          </p>
                          <p className="stats-label">Active</p>
                        </div>
                      </div>
                      <div
                        className={`col-md-${
                          userType === "admin" ? "2" : "3"
                        } pt-3`}>
                        <div className="stats-box">
                          <p className="stats-number">
                            {
                              members
                                ?.filter((member) =>
                                  userType === "coach"
                                    ? member?.assignedCoachId == loginUser?.id
                                    : member,
                                )
                                ?.filter(
                                  (val) => val?.status_category === "completed",
                                )?.length
                            }
                          </p>
                          <p className="stats-label">Completed</p>
                        </div>
                      </div>
                      <div className="col-md-3 stats-box-bm-2">
                        <p className="stats-number-bm h-100">                          
                          {members?.filter((user) => !user?.isCoach)?.length}
                        </p>
                        <p className="stats-label-bm">
                          BTM2 <br /> Members <br /> All Time
                        </p>
                      </div>

                      {userType !== "coach" && (
                        <div className="col-md-3 pt-3">
                          <div className="stats-box">
                            <p className="stats-number">
                              {" "}
                              {members?.filter((user) => user?.isCoach)?.length}
                            </p>
                            <p className="stats-label">Coaches</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 g-3">
                <div className="card">
                  <h5 className="card-header pb-3 fw-semibold">
                    Number Of Signups
                  </h5>

                  <div className="card-body">
                    {loading ? (
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: 700,
                          display: "flex",
                          justifyContent: "center",
                        }}>
                        Loading...
                      </p>
                    ) : (
                      <div id="weight"></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalState;
