import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryAction,
  getReceipe,
  getReceipeByCategory,
  getReceipeBySearch,
} from "../../store/actions/dashboardAction";
import { usePagination } from "../../apis/hooks";
import RecipeDetails from "./recipe-details";
import AddRecipeForm from "./add-recipe-form";

const Receipe = () => {
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allReceipes, setAllReceipes] = useState([]);
  const [page, setPage] = useState("listing");
  const [loading, setLoading] = useState(false);
  const [recipeDetails, setReceipeDetails] = useState({});

  const dispatch = useDispatch();

  const { receipes, totalRecipes, categories } = useSelector(
    (state) => state.dashboard,
  );
  const { isLoading } = useSelector((state) => state.ui);

  const pagination = usePagination(totalRecipes, 12);

  useEffect(() => {
    // if (pagination.current > 1) {
    if (searchText) {
      dispatch(getReceipeBySearch(pagination.current, searchText));
    } else if (category) {
      getDataByCategory(category, true);
    } else {
      dispatch(getReceipe(pagination.current));
    }
    // }
  }, [pagination.current]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAllReceipes(receipes);
  }, [receipes, pagination.current]);

  useEffect(() => {
    dispatch(getCategoryAction());
  }, []);

  const seeMoreHandler = (id) => {
    window.scrollTo(0, 0);
    setReceipeDetails(allReceipes?.find((receipe) => receipe?.id === id));
    setPage("detail");
  };

  const getDataBySearch = async () => {
    setLoading(true);
    pagination.goToPage(1);
    setCategory("");

    if (searchText) {
      await dispatch(getReceipeBySearch(pagination.current, searchText));
    } else {
      dispatch(getReceipe(pagination.current));
    }
    setLoading(false);
  };

  const getDataByCategory = async (id, pageChange = false) => {
    setLoading(true);
    setSearchText("");
    if (!pageChange) pagination.goToPage(1);
    setCategory(id);

    if (id) {
      await dispatch(getReceipeByCategory(id, pagination.current));
    } else {
      dispatch(getReceipe(1));
    }
    setLoading(false);
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        {page === "listing" ? (
          <div className="card-body">
            <div className="filter mb-4">
              <a
                role="button"
                onClick={() => {
                  getDataByCategory("");
                  pagination.goToPage(1);
                }}
                style={{ marginBottom: 15 }}
                className={`${category === "" ? "active" : ""} `}>
                All
              </a>
              {categories?.map((cate) => (
                <a
                  key={cate?.id}
                  role="button"
                  onClick={() => {
                    getDataByCategory(cate?.id);
                    pagination.goToPage(1);
                  }}
                  style={{ marginBottom: 15 }}
                  className={`${category === cate?.id ? "active" : ""} `}>
                  {cate?.name}({cate?.count})
                </a>
              ))}
            </div>

            <div
              className="row g-3 justify-content-between mb-4"
              style={{ marginTop: -15 }}>
              <form
                className="col-lg-6 col-md-7 row mt-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  getDataBySearch(null);
                }}>
                <div className="col-lg-6 col-md-8">
                  <div className="input-group input-group-merge">
                    <span
                      className="input-group-text"
                      id="basic-addon-search31">
                      <i className="bx bx-search"></i>
                    </span>
                    <input
                      type="text"
                      value={searchText}
                      className="form-control form-control-sm bg-white"
                      placeholder="Search recipes..."
                      aria-label="Search..."
                      aria-describedby="basic-addon-search31"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn br-10 w-100 recipe-button">
                    {loading ? "Searching" : "Search"}
                  </button>
                </div>
              </form>

              <div className="col-lg-6 col-md-5 text-md-end">
                <a
                  role="button"
                  onClick={() => setPage("add-form")}
                  className="btn btn-outline-primary br-10">
                  + Submit Your Own Recipe
                </a>
              </div>
            </div>

            <div className="row g-3 gy-md-5">
              {allReceipes?.map((receipe) => (
                <div
                  className="col-md-6 col-lg-4"
                  key={receipe?.id}>
                  <div className="receipe-block receipe-container">
                    <div className="receipe-img">
                      <img src={receipe?.image} />
                    </div>
                    <div className="receipe-details">
                      <h5 className="card-header pb-2 fw-semibold">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: receipe?.title,
                          }}></span>{" "}
                      </h5>
                      <div className="rp text-muted">
                        <div
                          style={{
                            height: 150,
                            overflow: "hidden",
                            marginBottom: 30,
                          }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: receipe?.ingredients
                                ?.replaceAll("<a", "<span")
                                ?.replaceAll("<a/>", "<span/>"),
                            }}></span>{" "}
                        </div>
                        <a
                          role="button"
                          style={{ color: "#088eac" }}
                          onClick={() => seeMoreHandler(receipe?.id)}
                          className="btn-more">
                          Show more <i className="bx bx-right-arrow-alt"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {!allReceipes?.length && (
                <div className="loading-text">
                  <p>{isLoading ? "Loading Recipes..." : "No Data"}</p>
                </div>
              )}
            </div>
            {pagination?.totalPages > 1 && !isLoading && (
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-end mt-5">
                  <li
                    className="page-item prev"
                    disabled={pagination.current === 1}>
                    <a
                      role="button"
                      onClick={pagination.onPrevClick}
                      className="page-link">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>

                  {pagination.links.map((item, idx) => {
                    if (item === "...") {
                      return (
                        <li className="page-item">
                          <p
                            style={{ marginLeft: 0 }}
                            className="page-link">
                            ...
                          </p>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          className={`page-item ${
                            pagination.current === item ? "active" : ""
                          }`}>
                          <a
                            className="page-link"
                            role="button"
                            onClick={() =>
                              pagination.current !== item &&
                              pagination.goToPage(item)
                            }>
                            {item}
                          </a>
                        </li>
                      );
                    }
                  })}

                  <li
                    className="page-item next"
                    disabled={pagination.current === pagination.totalPages}>
                    <a
                      role="button"
                      onClick={pagination.onNextClick}
                      className="page-link">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        ) : page === "add-form" || page === "success" ? (
          <AddRecipeForm
            setPage={setPage}
            page={page}
          />
        ) : (
          <>
            {recipeDetails && (
              <RecipeDetails
                recipeDetails={recipeDetails}
                setPage={setPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Receipe;
