import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTestimonial,
  affiliateCode,
  getPosts,
  referFriendHandler,
  savePosts,
  successScream,
} from "../store/actions/dashboardAction";
import FormTextField from "../forms/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const schema = yup.object({
  input_8: yup.string().trim().required("Cannot be empty"),
  input_22: yup.string().trim().required("Cannot be empty"),
  input_2: yup.string().trim().required("Cannot be empty"),
  input_12: yup.string().trim(),
});

const TestimonialUI = ({ closeModal }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState("form");

  const { loginUser } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loginUser?.name) {
      setValue("input_8", loginUser?.name);
    }
  }, [loginUser]);

  const saveData = async (data) => {
    setIsLoading(true);

    const res = await dispatch(addTestimonial(data));

    if (res.status === "success") {
      setPageType("success");
    }
    setIsLoading(false);
  };
  return (
    <div className="modal-content modal-content-refer">
      <div className="modal-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={closeModal}
          aria-label="Close">
          <i className="bx bx-plus bx-rotate-45"></i>
        </button>
        <h5 className="mb-2 bold">Add Testimonial</h5>
      </div>
      <div className="modal-body">
        {pageType === "form" ? (
          <form
            className="d-flex flex-column justify-content-between h-100"
            onSubmit={handleSubmit(saveData)}>
            <div>
              <FormTextField
                divClassName="col-md-12"
                innerDivClassName="col-md-12"
                label="Name"
                fieldName="input_8"
                register={register}
                errors={errors}
              />
              <FormTextField
                divClassName="col-md-12 mt-3"
                innerDivClassName="col-md-12"
                label="Achievement"
                fieldName="input_22"
                register={register}
                errors={errors}
              />

              <FormTextField
                divClassName="col-md-12 mt-3"
                innerDivClassName="col-md-12"
                label="Testimonial"
                fieldName="input_2"
                register={register}
                errors={errors}
              />
            </div>

            <div className="modal-footer mt-4">
              <button
                className="btn w-100 btn-lg br-10"
                type="submit"
                disabled={isLoading}>
                {isLoading ? "Processing" : "Submit"}
              </button>
            </div>
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <p style={{ fontSize: 18, color: "#1e2a42", fontWeight: 600 }}>
              Testimonial has been submitted!
            </p>
            <button className="btn w-50 btn-lg br-10 mt-4" onClick={closeModal}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestimonialUI;
